<mat-nav-list *ngIf="isUserAuthenticated">
    <!-- <a mat-list-item routerLink="/home" (click)="onSidenavClose()">
        <mat-icon>home</mat-icon> <span class="nav-caption">Home</span>
    </a> -->

    <a mat-list-item routerLink="/home" (click)="onSidenavClose()" *ngIf="hasPrivilege('PRIV_PORTAL_HOME_PAGE')"> 
         <!-- hasPortalRole || hasReadOnlyRole -->
        <mat-icon>home</mat-icon> <span class="nav-caption">Home</span>
    </a>

    <!-- <a mat-list-item routerLink="/homeBiller" (click)="onSidenavClose()" *ngIf="hasBillerRole&&!hasBillerRole">
        <mat-icon>home</mat-icon> <span class="nav-caption">Home</span>
    </a> -->

    <mat-list-item [matMenuTriggerFor]="menuMonitoring" *ngIf="hasAnyPrivilege(['PRIV_VP_JOB_MONITOR_VIEW','PRIV_CM_HISTORY_VIEW','PRIV_VP_TEST_SUITE_VIEW'])">
        <!-- 'PRIV_VP_RTPN_VIEWER', -->
        <!-- hasMonitorRole || hasRtpnRole || hasAdminRole || hasReadOnlyRole -->
        <mat-icon>unfold_more</mat-icon>
        <a matline>Monitoring</a>
        <mat-icon>event</mat-icon>
    </mat-list-item>
    <mat-menu #menuMonitoring="matMenu">
        <button mat-menu-item routerLink="/monitoring" (click)="onSidenavClose()" *ngIf="hasAllPrivileges(['PRIV_VP_JOB_MONITOR_VIEW','PRIV_VP_EVENT_OCCURANCE_VIEW'])">Events & Jobs<mat-icon>track_changes</mat-icon></button>
        <!-- hasMonitorRole -->
<!-- 
        <button mat-menu-item routerLink="/rtpnTransactions" (click)="onSidenavClose()" *ngIf="hasPrivilege('PRIV_VP_RTPN_VIEWER')">RTPN Transactions<mat-icon>money</mat-icon></button>
 -->
        <!-- hasRtpnRole -->
        <button mat-menu-item routerLink="/communicationHistory" (click)="onSidenavClose()" *ngIf="hasPrivilege('PRIV_CM_HISTORY_VIEW')">Communication History<mat-icon>history</mat-icon></button>
        <!-- hasMonitorRole -->
        <button mat-menu-item routerLink="/testSuite" (click)="onSidenavClose()" *ngIf="hasAnyPrivilege(['PRIV_VP_TEST_SUITE_VIEW','PRIV_VP_TEST_SUITE_ADD'])">Test Suite<mat-icon>event_available</mat-icon></button>
        <!-- hasAdminRole -->
    </mat-menu>

    <mat-list-item [matMenuTriggerFor]="menuAdministration" *ngIf="hasAnyPrivilege(['PRIV_WA_USER_VIEW','PRIV_WA_USER_MODIFY','PRIV_CM_SUBSCRIPTION_VIEW','PRIV_CM_SUBSCRIPTION_MODIFY','PRIV_WAL_USERS_MODIFY'])">
        <!-- hasAdminRole || hasUserAdminRole -->
        <mat-icon>unfold_more</mat-icon>
        <a matline>User Administration</a>
        <mat-icon>assignment_ind</mat-icon>
    </mat-list-item>
    <mat-menu #menuAdministration="matMenu">
        <button mat-menu-item routerLink="/portalRoles" (click)="onSidenavClose()" *ngIf="hasAnyPrivilege(['PRIV_WA_ROLE_VIEW','PRIV_WA_ROLE_MODIFY'])">Portal Roles<mat-icon>confirmation_number</mat-icon></button>
        <!-- hasAdminRole -->
        <button mat-menu-item routerLink="/portalUsers" (click)="onSidenavClose()" *ngIf="hasAnyPrivilege(['PRIV_WA_USER_MODIFY','PRIV_WA_USER_VIEW'])">Portal Users<mat-icon>perm_identity</mat-icon></button>
        <!-- hasAdminRole || hasUserAdminRole -->
      <button mat-menu-item routerLink="/groupOnboard" (click)="onSidenavClose()" *ngIf="hasPrivilege('PRIV_WA_USER_MODIFY')">Group Onboard<mat-icon>people_outline</mat-icon></button>
      <!-- hasAdminRole -->
      <button mat-menu-item routerLink="/communicationSubscribers" (click)="onSidenavClose()" *ngIf="hasAnyPrivilege(['PRIV_CM_SUBSCRIPTION_VIEW','PRIV_CM_SUBSCRIPTION_MODIFY'])">Communication Subscribers<mat-icon>notifications</mat-icon></button>
      <!-- hasAdminRole -->
      <button mat-menu-item routerLink="/users" (click)="onSidenavClose()" *ngIf="hasAnyPrivilege(['PRIV_WAL_USERS_VIEW','PRIV_WAL_USERS_MODIFY'])">Walletron Users<mat-icon>supervised_user_circle</mat-icon></button>
      <!-- hasAdminRole -->
    </mat-menu>

    <mat-list-item [matMenuTriggerFor]="menuBillerOnboarding" *ngIf="hasAnyPrivilege(['PRIV_VP_BRAND_BOOTSTRAP','PRIV_WAL_BRAND_CONFIG_TEMPLATE_VIEW','PRIV_WAL_BRAND_CONFIG_TEMPLATE_MODIFY','PRIV_WAL_BRAND_CONFIG_ENROLL_LINKS'])">
        <!-- hasBrandBootstrapRole || hasBrandConfigRole || hasReadOnlyRole -->
        <mat-icon>unfold_more</mat-icon>
        <a matline>Brand Administration</a>
        <mat-icon>business_center</mat-icon>
    </mat-list-item>
    <mat-menu #menuBillerOnboarding="matMenu">
        <button mat-menu-item routerLink="/brandBootstrap" (click)="onSidenavClose()" *ngIf="hasPrivilege('PRIV_VP_BRAND_BOOTSTRAP')">Brand Bootstrap<mat-icon>palette</mat-icon></button>
        <!-- hasBrandBootstrapRole -->
        <button mat-menu-item routerLink="/brandProfile" (click)="onSidenavClose()" *ngIf="hasAllPrivileges(['PRIV_WAL_BRAND_CONFIG_IMAGE_MODIFY','PRIV_WAL_BRAND_INFO'])">Brand Profile<mat-icon>domain</mat-icon></button>
        <!-- hasBrandConfigRole -->
        <button mat-menu-item routerLink="/brandPassTemplates" (click)="onSidenavClose()" *ngIf="hasAnyPrivilege(['PRIV_WAL_BRAND_CONFIG_PASS_VIEW','PRIV_WAL_BRAND_CONFIG_PASS_MODIFY'])">Pass Templates<mat-icon>table_chart</mat-icon></button>
        <!-- hasBrandConfigRole -->
        <button mat-menu-item routerLink="/messageTemplates" (click)="onSidenavClose()" *ngIf="hasAnyPrivilege(['PRIV_WAL_BRAND_CONFIG_MSG_TEMPLATE_VIEW','PRIV_WAL_BRAND_CONFIG_MSG_TEMPLATE_MODIFY'])">Message Templates<mat-icon>tap_and_play</mat-icon></button>
        <!-- hasBrandConfigRole -->
        <button mat-menu-item routerLink="/brandVariables" (click)="onSidenavClose()" *ngIf="hasAnyPrivilege(['PRIV_WAL_BRAND_CONFIG_VARIABLE_MODIFY','PRIV_WAL_BRAND_CONFIG_VARIABLE_VIEW'])">Brand Variables<mat-icon>developer_board</mat-icon></button>
        <!-- hasBrandConfigRole -->
        <button mat-menu-item routerLink="/brandOffers" (click)="onSidenavClose()" *ngIf="hasAnyPrivilege(['PRIV_WAL_BRAND_CONFIG_OFFER_MODIFY','PRIV_WAL_BRAND_CONFIG_OFFER_VIEW'])">Brand Field Sets<mat-icon>format_list_numbered_rtl</mat-icon></button>
        <!-- hasBrandConfigRole -->
        <button mat-menu-item routerLink="/brandConfigProperty" (click)="onSidenavClose()" *ngIf="hasAnyPrivilege(['PRIV_WAL_BRAND_CONFIG_PROP_MODIFY','PRIV_WAL_BRAND_CONFIG_PROP_VIEW'])">Brand Config Property<mat-icon>category</mat-icon></button>
        <!-- hasBrandConfigRole -->
        <button mat-menu-item routerLink="/brandImages" (click)="onSidenavClose()" *ngIf="hasAnyPrivilege(['PRIV_WAL_BRAND_CONFIG_IMAGE_VIEW','PRIV_WAL_BRAND_CONFIG_IMAGE_MODIFY'])">Brand Images<mat-icon>camera_alt</mat-icon></button>
        <!-- hasBrandConfigRole -->
        <button mat-menu-item routerLink="/emailSmsTemplates" (click)="onSidenavClose()" *ngIf="hasAnyPrivilege(['PRIV_WAL_BRAND_CONFIG_TEMPLATE_VIEW','PRIV_WAL_BRAND_CONFIG_TEMPLATE_MODIFY'])">Email/SMS Templates<mat-icon>email</mat-icon></button>
        <!-- hasBrandConfigRole -->
        <button mat-menu-item routerLink="/configMappings" (click)="onSidenavClose()" *ngIf="hasAnyPrivilege(['PRIV_WAL_CONFIG_MAPPING_VIEW','PRIV_WAL_CONFIG_MAPPING_MODIFY'])">Config Mappings<mat-icon>view_comfy</mat-icon></button>
        <!-- hasBrandConfigRole -->
        <button mat-menu-item routerLink="/enrollmentLinks" (click)="onSidenavClose()" *ngIf="hasPrivilege('PRIV_WAL_BRAND_CONFIG_ENROLL_LINKS')">Enrollment Links<mat-icon>link</mat-icon></button>
        <!-- hasBrandConfigRole -->
        <button mat-menu-item routerLink="/qrcodeGeneration" (click)="onSidenavClose()" *ngIf="hasPrivilege('PRIV_WAL_BRAND_CONFIG_ENROLL_LINKS')">QR Codes<mat-icon>grain</mat-icon></button>
        <button mat-menu-item routerLink="/scheduledEvents" (click)="onSidenavClose()" *ngIf="hasAnyPrivilege(['PRIV_MS_EVENTS_VIEW','PRIV_MS_EVENTS_MODIFY'])">Scheduled Events<mat-icon>access_alarms</mat-icon></button>
        <!-- hasBrandConfigRole -->
    </mat-menu>

    <mat-list-item [matMenuTriggerFor]="menuCustomizations" *ngIf="hasAllPrivileges(['PRIV_WP_BRAND_VIEW','PRIV_WR_ADHOC_RPT']) || hasAnyPrivilege(['PRIV_WR_ADHOC_RPT','PRIV_CM_SUBSCRIPTION_MODIFY','PRIV_VP_BRAND_MIGRATE'])">
        <!-- hasAdminRole || hasBrandListingRole -->
        <mat-icon>unfold_more</mat-icon>
        <a matline>Customizations</a>
        <mat-icon>build</mat-icon>
    </mat-list-item>
    <mat-menu #menuCustomizations="matMenu">
        <button mat-menu-item routerLink="/brandListing" (click)="onSidenavClose()" *ngIf="hasAllPrivileges(['PRIV_WP_BRAND_VIEW','PRIV_WR_ADHOC_RPT'])">Brand Listing<mat-icon>card_membership</mat-icon></button>
        <!-- hasAdminRole || hasBrandListingRole -->
        <button mat-menu-item routerLink="/brandMigration" (click)="onSidenavClose()" *ngIf="hasPrivilege('PRIV_VP_BRAND_MIGRATE')">Brand Migration<mat-icon>swap_horizontal_circle</mat-icon></button>
        <!-- hasAdminRole -->
        <button mat-menu-item routerLink="/configProperty" (click)="onSidenavClose()" *ngIf="hasAnyPrivilege(['PRIV_WAL_BRAND_MASTER_CONFIG_PROP_MODIFY','PRIV_WAL_BRAND_MASTER_CONFIG_PROP_VIEW'])">Master Config Property<mat-icon>settings_applications</mat-icon></button>
        <!-- <button mat-menu-item routerLink="/se-listing" (click)="onSidenavClose()">Scheduled Events Listing<mat-icon>view_list</mat-icon></button> -->
        <!-- hasAdminRole -->
        <button mat-menu-item routerLink="/messageProfile" (click)="onSidenavClose()" *ngIf="hasPrivilege('PRIV_CM_SUBSCRIPTION_MODIFY')">Message Profile<mat-icon>event_note</mat-icon></button>
        <!-- hasAdminRole -->
        <button mat-menu-item routerLink="/encryptionUtility" (click)="onSidenavClose()" *ngIf="hasPrivilege('PRIV_WP_ENCRYPTION')">Encryption Utility<mat-icon>security</mat-icon></button>
        <!-- hasAdminRole -->
    </mat-menu>

    <mat-list-item [matMenuTriggerFor]="menuAccountAdministration" *ngIf="hasAnyPrivilege(['PRIV_WAL_ACCOUNT_INFO','PRIV_WAL_MESSAGE_TO_ACCOUNT','PRIV_WAL_ACCOUNT_MESSAGE'])">
        <!-- hasAcctAdminRole -->
        <mat-icon>unfold_more</mat-icon>
        <a matline>Account Administration</a>
        <mat-icon>cast_connected</mat-icon>
    </mat-list-item>
    <mat-menu #menuAccountAdministration="matMenu">
        <button mat-menu-item routerLink="/accountInfo" (click)="onSidenavClose()" *ngIf="hasPrivilege('PRIV_WAL_ACCOUNT_INFO')">Account Info<mat-icon>location_city</mat-icon></button>
        <!-- hasAcctAdminRole -->
        <button mat-menu-item routerLink="/messageBroadcasting" (click)="onSidenavClose()" *ngIf="hasAnyPrivilege(['PRIV_WAL_MESSAGE_TO_ACCOUNT','PRIV_WAL_ACCOUNT_MESSAGE'])">Message Broadcasting<mat-icon>vibration</mat-icon></button>
        <!-- <button mat-menu-item routerLink="/adhoc-notification" (click)="onSidenavClose()" *ngIf="hasAnyPrivilege(['PRIV_WAL_FILTER_CONFIG_VIEW'])">Ad-hoc Notification<mat-icon>system_update</mat-icon></button> -->
        <!-- hasAcctAdminRole -->
    </mat-menu>


    <mat-list-item [matMenuTriggerFor]="menuAccountCSR" *ngIf="hasAnyPrivilege(['PRIV_VP_ACCT_CSR','PRIV_WAL_FILTER_CONFIG_MODIFY',
        'PRIV_WAL_ADHOC_MSG_TEMPLATE_MODIFY','PRIV_WAL_ADHOC_MSG_CONFIG','PRIV_WAL_MESSAGE_BATCH_EXEC_VIEW','PRIV_WAL_ADHOC_MSG_EXECUTE'])">
        <mat-icon>unfold_more</mat-icon>
        <a matline>CSR</a>
        <mat-icon>headset_mic</mat-icon>
    </mat-list-item>
    <mat-menu #menuAccountCSR="matMenu">
        <button mat-menu-item routerLink="/csrAccount" (click)="onSidenavClose()" *ngIf="hasAnyPrivilege(['PRIV_VP_ACCT_CSR'])">Enrollment Invitation<mat-icon>tablet_android</mat-icon></button>
        <button mat-menu-item routerLink="/adhoc-msg-config" (click)="onSidenavClose()" *ngIf="hasAllPrivileges(['PRIV_WAL_FILTER_CONFIG_MODIFY',
            'PRIV_WAL_ADHOC_MSG_TEMPLATE_MODIFY','PRIV_WAL_ADHOC_MSG_CONFIG','PRIV_WAL_MESSAGE_BATCH_EXEC_VIEW','PRIV_WAL_ADHOC_MSG_EXECUTE'])">Target Communications<mat-icon>system_update</mat-icon></button>
        <button mat-menu-item routerLink="/msg-exec-viewer" (click)="onSidenavClose()" *ngIf="hasAnyPrivilege(['PRIV_WAL_MESSAGE_BATCH_EXEC_VIEW'])">Message Executions<mat-icon>receipt</mat-icon></button>
        </mat-menu>    

<!-- 
    <a mat-list-item routerLink="/csrAccount" (click)="onSidenavClose()" *ngIf="hasPrivilege('PRIV_VP_ACCT_CSR')">
         <span class="nav-caption">CSR</span>   <mat-icon>headset_mic</mat-icon>
    </a>
 -->
    <mat-list-item [matMenuTriggerFor]="menuStatistics" *ngIf="hasPrivilege('PRIV_WR_STATS')">
        <!-- hasMonitorRole || hasStatsViewerRole -->
        <mat-icon>unfold_more</mat-icon>
        <a matline>Statistics</a>
        <mat-icon>developer_board</mat-icon>
    </mat-list-item>
    <mat-menu #menuStatistics="matMenu">
        <button mat-menu-item routerLink="/clientDashboard" (click)="onSidenavClose()" *ngIf="hasPrivilege('PRIV_WR_STATS')">Client Dashboard<mat-icon>dashboard</mat-icon></button>
        <button mat-menu-item routerLink="/enrollmentStats" (click)="onSidenavClose()" *ngIf="hasPrivilege('PRIV_WR_STATS')">Enrollment Stats<mat-icon>beenhere</mat-icon></button>
        <!-- hasMonitorRole || hasStatsViewerRole -->
        <button mat-menu-item routerLink="/clickthroughStats" (click)="onSidenavClose()" *ngIf="hasPrivilege('PRIV_WR_STATS')">Clickthroughs<mat-icon>nfc</mat-icon></button>
        <!-- hasMonitorRole || hasStatsViewerRole -->
        <button mat-menu-item routerLink="/notificationStats" (click)="onSidenavClose()" *ngIf="hasPrivilege('PRIV_WR_STATS')">Notifications<mat-icon>message</mat-icon></button>
        <!-- hasMonitorRole || hasStatsViewerRole -->
        <button mat-menu-item routerLink="/enrollmentTrend" (click)="onSidenavClose()" *ngIf="hasPrivilege('PRIV_VP_STATS')">Enrollment Trend<mat-icon>trending_up</mat-icon></button>
        <!-- hasMonitorRole || hasStatsViewerRole -->
    </mat-menu>

    <mat-list-item [matMenuTriggerFor]="menuReports" *ngIf="hasAnyPrivilege(['PRIV_WR_RPT','PRIV_VP_STATS'])">
        <!-- hasMonitorRole -->
        <mat-icon>unfold_more</mat-icon>
        <a matline>Reports</a>
        <mat-icon>dvr</mat-icon>
    </mat-list-item>
    <mat-menu #menuReports="matMenu">
        <button mat-menu-item routerLink="/summaryReports" (click)="onSidenavClose()" *ngIf="hasPrivilege('PRIV_WR_RPT')">Summary Reports<mat-icon>business</mat-icon></button>
        <!-- hasMonitorRole -->
        <button mat-menu-item routerLink="/enrollmentReport" (click)="onSidenavClose()" *ngIf="hasPrivilege('PRIV_VP_STATS')">Enrollment Report<mat-icon>group_add</mat-icon></button>
        <!-- hasMonitorRole -->
    </mat-menu>

    <mat-list-item [matMenuTriggerFor]="menuDemoSupport" *ngIf="hasAnyPrivilege(['PRIV_WAL_DEMO_SCENARIOS','PRIV_WAL_DEMO_DISTRIBUTION','PRIV_WAL_ACCOUNT_MESSAGE'])">
        <!-- hasDemoSupportRole -->
        <mat-icon>unfold_more</mat-icon>
        <a matline>Demo Support</a>
        <mat-icon>live_tv</mat-icon>
    </mat-list-item>
    <mat-menu #menuDemoSupport="matMenu">
        <button mat-menu-item routerLink="/accountTasks" (click)="onSidenavClose()" *ngIf="hasAllPrivileges(['PRIV_WAL_ACCOUNT_INFO','PRIV_WAL_MESSAGE_TO_ACCOUNT','PRIV_WAL_ACCOUNT_UPDATE','PRIV_WAL_ACCOUNT_MESSAGE','PRIV_WAL_DEMO_SCENARIOS'])">Account Tasks<mat-icon>ballot</mat-icon></button>
        <!-- hasDemoSupportRole -->
        <button mat-menu-item routerLink="/enrollmentInvitation" (click)="onSidenavClose()" *ngIf="hasPrivilege('PRIV_WAL_DEMO_DISTRIBUTION')">Enrollment Invitation<mat-icon>nfc</mat-icon></button>
        <!-- hasDemoSupportRole -->
    </mat-menu>

    <mat-list-item [matMenuTriggerFor]="menuRTPN" *ngIf="hasAnyPrivilege(['PRIV_VP_RTPN_VIEWER','PRIV_RTPN_TEST'])">
        <mat-icon>unfold_more</mat-icon>
        <a matline>RTPN</a>
        <mat-icon>local_atm</mat-icon>
    </mat-list-item>
    <mat-menu #menuRTPN="matMenu">
        <button mat-menu-item routerLink="/rtpnTransactions" (click)="onSidenavClose()" *ngIf="hasPrivilege('PRIV_VP_RTPN_VIEWER')">RTPN Transactions<mat-icon>money</mat-icon></button>
        <button mat-menu-item routerLink="/rtpnSimulator" (click)="onSidenavClose()" *ngIf="hasPrivilege('PRIV_RTPN_TEST')">RTPN Simulator<mat-icon>developer_mode</mat-icon></button>
    </mat-menu>

    <a mat-list-item routerLink="/userProfile" (click)="onSidenavClose()" *ngIf="hasPrivilege('PRIV_PORTAL_USER_PROFILE')">
        <span class="nav-caption">My Account</span>   <mat-icon>person</mat-icon>
   </a>

</mat-nav-list>


