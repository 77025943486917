import { Component, OnInit, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { Validators,FormControl,FormGroup,FormBuilder,ValidatorFn } from '@angular/forms';
import { ConfirmationService } from "primeng/api";
import cronstrue from 'cronstrue/i18n';
import { BrandService } from '../../services/config/brand.service';
import { FilterBuilderService } from '../../services/client/filter-builder.service';
import { AdhocMessageConfigService } from '../../services/client/adhoc-message-config.service';
import { AdhocMessageSchedulerService } from '../../services/client/adhoc-message-scheduler.service';
import { AdhocMessageTemplateService } from '../../services/client/adhoc-message-template.service';
import { UserContextService } from "../../services/safeguard/user-context.service";
import { TokenStorageService } from '../../services/safeguard/token-storage.service';
import { SharedService } from "../../services/shared.service";
import { IBrand } from '../../interfaces/brand';
import { IFilterConfig } from '../../interfaces/filter-config';
import { IMessageTemplate } from '../../interfaces/message-template';
import { IAdhocMessageConfig } from '../../interfaces/adhoc-message-config';

@Component({
  selector: 'app-adhoc-message-config',
  templateUrl: './adhoc-message-config.component.html',
  styleUrl: './adhoc-message-config.component.css'
})
export class AdhocMessageConfigComponent {
  titleMessage: string = "Adhoc Message Config";
  adhocMsgConfigForm!: FormGroup;

  currentBrand!: IBrand;
  brandImage: any;
  brandCode!: string;
  baseUrl!: string;

  isImagePreviewShown: boolean = false;
  isBrandSelectionButtonShown: boolean = false;
  isSetScheduleButtonShown: boolean = false;
  isExecuteNowButtonShown: boolean = false;
  isPutOnScheduleButtonShown: boolean = false;
  isRemoveFromScheduleButtonShown: boolean = false;
  openBrandSelectionDialog: boolean = false;
  openFilterBuilderDialog: boolean = false;
  openMessageTemplateDialog: boolean = false;
  openCronSchedulerDialog: boolean = false;
  openCronEditorDialog: boolean = false;

  isAdhocMessageConfigDialogVisible: boolean = false;
  isAdhocMessageConfigNew: boolean = false;
  adhocMessageConfigs: IAdhocMessageConfig[] = [];
  adhocMessageConfigCols: any[] = [];
  selectedAdhocMessageConfig: IAdhocMessageConfig = <IAdhocMessageConfig> {};
  modifiedAdhocMessageConfigId!: number;

  // cronExpression: any = '';
  // adhocMsgConfigId!: number;

  resultMsg!: string;
  currentUser!: string;

  defaultErrorMessage: string = "Error processing the request.  Please report to the Walletron Support team.";

  // filterConfigs: any[] = [];
  // filterConfigsMaster: any[] = [];

  currentBrandSubject: Subject<any> = new Subject();

  adhocMsgConfigIdSubject: Subject<number> = new Subject();

  constructor(
    private fb: FormBuilder, 
    private tokenStorageService: TokenStorageService,
    private userContextService: UserContextService,
    private confirmationService: ConfirmationService,
    private adhocMessageConfigService: AdhocMessageConfigService,
    private adhocMessageSchedulerService: AdhocMessageSchedulerService,
    private adhocMessageTemplateService: AdhocMessageTemplateService,
    private sharedService: SharedService,    
    private brandService: BrandService,
    private filterBuilderService: FilterBuilderService,
    ) {}

    ngOnInit(): void {
      this.currentUser = this.tokenStorageService.getCurrentUser().userName;
      this.adhocMsgConfigForm = this.fb.group({
        'adhocMessageConfigId': new FormControl(null),
        'messageConfigName': new FormControl('', [Validators.required]),
        'description': new FormControl(''),
        'filterConfigId': new FormControl('', [Validators.required, Validators.min(1)]),
        'filterName': new FormControl('', [Validators.required]),
        'filterLogic': new FormControl(''),
        'messageTemplateCode': new FormControl('', [Validators.required]),
        'messageTemplateName': new FormControl('', [Validators.required]),
        'messageTemplateHeader': new FormControl(''),
        'messageTemplateText': new FormControl(''),
        'schedulerConfig': new FormControl(''),
        'humanCron': new FormControl('')
      });
      // Define the columns on the grid
      this.adhocMessageConfigCols = [
        { field: 'messageConfigName', header: 'Message Config Name', display: 'table-cell', width:'20%' },
        { field: 'filterName', header: 'Filter Name', display: 'table-cell', width:'25%' },
        { field: 'messageTemplateName', header: 'Message Template Name', display: 'table-cell', width:'20%' },
        { field: 'modifiedByUser', header: 'Modified By', display: 'table-cell', width:'15%' },
        { field: 'schedulerStatus', header: 'Scheduler', display: 'table-cell', width:'12%' },
        
      ];     
      // If the current brand is already known, then use it as the default 
      if (this.sharedService.isCurrentBrandKnown()) {
        this.currentBrand = this.sharedService.getCurrentBrand();
        this.titleMessage = this.makeTitleMessage (this.currentBrand);
        this.brandCode = this.currentBrand.brandCode;
        this.baseUrl = this.currentBrand.baseUrl;
        this.getAdhocMessageConfigs(this.brandCode, this.baseUrl);
        this.openBrandSelectionDialog = false;
      } else {
  
        // There is no current brand yet - find out a set of brands (user attributes) that have been granted to them
        let grantedBrands = this.userContextService.getUserBrands();
        if (grantedBrands) {
          // Case I: the user was granted exactly one brand - make it as the current brand and skip the brand selection dialog
          if (grantedBrands.length == 1) {
            this.setSingleBrand(grantedBrands[0]);
          }
          // Case II: the user was granted multiple brands - popup the brand selection dialog
          else {
            this.isBrandSelectionButtonShown = true;
            this.openBrandSelectionDialog = true;
          }
        } 
        // Case III: no individual brands granted, rather all brands attribute
        else {
          this.isBrandSelectionButtonShown = true;
          this.openBrandSelectionDialog = true;
        }
      }
    }

  // When the user opens up the dialog to select a brand 
  onChooseBrandClick(event: any) {
    this.openBrandSelectionDialog = true;
  }

  // When a brand is chosen
  chooseBrand(item: IBrand) {
    this.openBrandSelectionDialog = false;
    if (item != null) {
      this.currentBrand = item;
      this.brandCode = this.currentBrand.brandCode;
      this.baseUrl = this.currentBrand.baseUrl;
      this.titleMessage = this.makeTitleMessage (item);
      this.sharedService.setCurrentBrand(item);
      this.getAdhocMessageConfigs(item.brandCode, item.baseUrl);
      // Notify the Filter Builder component of the selected brand
      this.currentBrandSubject.next(item);
    }
  }
    
  // Set single brand as the default when the user has just one brand granted
  private setSingleBrand(brandCode: string) {
    this.brandService.getBrandWithRegion(brandCode)
    .subscribe (brand => {
      this.currentBrand = brand;
      this.brandCode = this.currentBrand.brandCode;
      this.baseUrl = this.currentBrand.baseUrl;
      this.titleMessage = this.makeTitleMessage (this.currentBrand);
      this.getAdhocMessageConfigs(this.brandCode, this.baseUrl);
    });
  }

  // Retrieve all existing adhoc message configurations for the current brand
  private getAdhocMessageConfigs(brandCode: string, baseUrl: string) {
    this.isExecuteNowButtonShown = false;
    this.isPutOnScheduleButtonShown = false;
    this.isRemoveFromScheduleButtonShown = false;
    this.adhocMessageConfigs = [];
    this.adhocMessageConfigService.getAdhocMessageConfigsForBrand(brandCode, baseUrl || '')
    .subscribe({
      next: (response) => {
        console.log('response', response);
        if (response) {
          this.adhocMessageConfigs = response;
        }
      },
      error: (error) => {
        this.alertTheUser(this.defaultErrorMessage);
      },
      complete: () => {
      }
    });
  }

  // When the user selects a row to identify a chosen adhoc message config
  onRowAdhocMessageConfigSelect(event: any) {
    this.selectedAdhocMessageConfig = <IAdhocMessageConfig>  {
      adhocMessageConfigId: event.data.adhocMessageConfigId,
      brandCode: event.data.brandCode,
      filterConfigId: event.data.filterConfigId,
      messageTemplateCode: event.data.messageTemplateCode,
      messageConfigName: event.data.messageConfigName,
      description: event.data.description,
      schedulerConfig: event.data.schedulerConfig,
      createdByUser: event.data.createdByUser,
      createDate: event.data.createDate,
      modifiedByUser: event.data.modifiedByUser,
      lastUpdateDate: event.data.lastUpdateDate,
      messageTemplateName: event.data.messageTemplateName || '',
      filterName: event.data.filterName,
      filterLogic: event.data.filterLogic
    };
    this.isExecuteNowButtonShown = true;
    this.isPutOnScheduleButtonShown = false;
    this.isRemoveFromScheduleButtonShown = false;
    switch(event.data.schedulerStatus) {
      case 'SCHEDULED':
        this.isRemoveFromScheduleButtonShown = true;
        break;
      case 'CONFIGURED':
        this.isPutOnScheduleButtonShown = true;
        break;
      default:
        break;
    }
  }

  // To modify an existing adhoc message config
  onModifyAdhocMessageConfig(event: any) {
    this.modifiedAdhocMessageConfigId = event.adhocMessageConfigId;
    this.adhocMessageConfigService.getAdhocMessageConfigById(this.brandCode, this.modifiedAdhocMessageConfigId, this.baseUrl)
    .subscribe({
      next: (response) => {
        if (response) {
          console.log('response', response);
          // console.log('lastUpdateDate', this.sharedService.formatFromISO(response.lastUpdateDate || ''));
          this.adhocMsgConfigForm.setValue({
            adhocMessageConfigId: response.adhocMessageConfigId,
            messageConfigName: response.messageConfigName,
            description: response.description,
            filterConfigId: response.filterConfigId,
            filterName: response.filterName,
            filterLogic: response.filterLogic,
            messageTemplateCode: response.messageTemplateCode,
            messageTemplateName: response.messageTemplateName,
            messageTemplateHeader: '',
            messageTemplateText: '',
            // lastUpdateDate: this.sharedService.formatFromISO(response.lastUpdateDate || ''),
            // schedulingType: response.schedulerConfig ? 'bySchedule' : 'onDemand'
            schedulerConfig: response.schedulerConfig || '',
            humanCron: response.cronExpression ? cronstrue.toString(response.cronExpression, {locale: 'en', dayOfWeekStartIndexZero: false}) : ''
          });
          this.findMessageTemplate(response.messageTemplateCode);
          this.isAdhocMessageConfigNew = false;
          this.isAdhocMessageConfigDialogVisible = true;
          this.isSetScheduleButtonShown = (response.schedulerConfig != null);
        }
      },
      error: (error) => {
        this.alertTheUser(this.defaultErrorMessage);
      },
      complete: () => {
      }
    });
  }

  // Locate specific message template
  findMessageTemplate(messageTemplateCode: string) {
    this.adhocMessageTemplateService.getMessageTemplateById(this.brandCode, messageTemplateCode, this.baseUrl)
    .subscribe({
      next: (response) => {
        if (response) {
          this.adhocMsgConfigForm.patchValue({
            messageTemplateHeader: response.messageTemplateHeader,
            messageTemplateText: response.messageTemplateText,
          });
        }
      },
      error: (error) => {
        this.alertTheUser(this.defaultErrorMessage);
      },
      complete: () => {
      }
    });
  }
  

  // To delete an existing adhoc message config
  onDeleteAdhocMessageConfig(event: any) {
    let adhocMessageConfigId: number = event.adhocMessageConfigId;
    let messageConfigName: string = event.messageConfigName;
    this.confirmationService.confirm({
      message: 'Are you sure that you really want to delete the adhoc message config ' + messageConfigName + ' ?',
      header: 'Confirmation',
      key: 'adhoc-message-config',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "Yes",
      rejectVisible: true,
      acceptButtonStyleClass: "p-button-success p-button-rounded",
      rejectButtonStyleClass: "p-button-danger p-button-rounded",   
      accept: () => {
        // Call the service to invoke a Web API call
        this.adhocMessageConfigService.deleteAdhocMessageConfig(this.brandCode, adhocMessageConfigId, this.baseUrl)
          .subscribe({
          next: (response) => {
            this.alertTheUser('Successfully deleted adhoc message config ' + messageConfigName + ' from brand '
                + this.brandCode);
            this.getAdhocMessageConfigs(this.brandCode, this.baseUrl);
            this.isAdhocMessageConfigDialogVisible = false;
          },
          error: (error) => {
            this.alertTheUser(this.defaultErrorMessage);
          },
          complete: () => {
          }
        });               
      },
      reject: () => {
      }
    });
  }


  // // When the user submits an on-demand request to send out an adhoc notification
  // onSubmit(event: any) {
  //   let adhocNotifRequest = {
  //     brandCode: this.currentBrand.brandCode,
  //     filterConfigId: this.adhocNotifForm.value.filterConfigId,
  //     messageTemplateCode: this.adhocNotifForm.value.messageTemplateCode,
  //     adhocNotifName: null
  //   }
  //   this.adhocNotificationService.submitOnDemandRequest(this.currentBrand.brandCode, adhocNotifRequest, this.currentBrand.baseUrl)
  //   .subscribe({
  //     next: (response) => {
  //       if (response) {
  //         // this.alertTheUser('Successfully submitted the adhoc notification for brand '
  //         //     + this.currentBrand.brandCode);
  //       }
  //     },
  //     error: (error) => {
  //       // this.alertTheUser(error.message);
  //     },
  //     complete: () => {
  //     }
  //   });
  // }

  // When the user wants to open up filter configurations dialog
  onSelectFilterClick() {
    this.openFilterBuilderDialog = true;
  }

  // When the user comes back from the Filter Builder dialog with a selected filter config
  selectFilterConfig(filterConfig: IFilterConfig) {
    if (filterConfig) {
      this.adhocMsgConfigForm.patchValue({
        'filterConfigId': filterConfig.filterConfigId,
        'filterName': filterConfig.filterName,
        'filterLogic': filterConfig.filterLogic
      });
    }
    this.openFilterBuilderDialog = false;
  }

  // When the user wants to open up Message Template dialog
  onSelectMessageTemplateClick() {
    this.openMessageTemplateDialog = true;
  }

  // When the user comes back from the MessageTemplate dialog with a selected filter config
  selectMessageTemplate(messageTemplate: IMessageTemplate) {
    if (messageTemplate) {
      this.adhocMsgConfigForm.patchValue({
        'messageTemplateCode': messageTemplate.messageTemplateCode,
        'messageTemplateName': messageTemplate.messageTemplateName,
        'messageTemplateHeader': messageTemplate.messageTemplateHeader,
        'messageTemplateText': messageTemplate.messageTemplateText
     });
    }
    this.openMessageTemplateDialog = false;
  }

  onNewAdhocMessageConfig() {
    this.adhocMsgConfigForm.setValue({
      messageConfigName: '',
      description: '',
      adhocMessageConfigId: null,
      filterConfigId: null,
      filterName: '',
      filterLogic: '',
      messageTemplateCode: '',
      messageTemplateName: '',
      messageTemplateHeader: '',
      messageTemplateText: '',
      // schedulingType: 'onDemand'
      schedulerConfig: '',
      humanCron: ''
    });
    this.isSetScheduleButtonShown = false;
    this.isAdhocMessageConfigNew = true;
    this.isAdhocMessageConfigDialogVisible = true;
  }

  // To execute now adhoc message
  onRunAdhocMessageConfig() {
    if (!this.selectedAdhocMessageConfig.adhocMessageConfigId) return;
    this.adhocMessageConfigService.sendAdhocMessage(this.brandCode, this.selectedAdhocMessageConfig.adhocMessageConfigId, this.baseUrl)
    .subscribe({
      next: (response) => {
        if (response) {
          this.alertTheUser('Successfully submitted ' + this.selectedAdhocMessageConfig.messageConfigName + ' for execution.');
        }
      },
      error: (error) => {
        this.alertTheUser(this.defaultErrorMessage);
      },
      complete: () => {
      }
    });
  }

  // To put on schedule adhoc message
  onPutOnScheduleAdhocMessageConfig() {
    if (!this.selectedAdhocMessageConfig.adhocMessageConfigId) return;
    this.adhocMessageSchedulerService.putOnScheduleConfig(this.brandCode, this.selectedAdhocMessageConfig.adhocMessageConfigId, this.baseUrl)
    .subscribe({
      next: (response) => {
        this.getAdhocMessageConfigs(this.brandCode, this.baseUrl);
        this.alertTheUser('Successfully put on schedule adhoc notification configuration ' + this.selectedAdhocMessageConfig.messageConfigName + ' for brand '
              + this.brandCode);
      },
      error: (error) => {
        this.alertTheUser(this.defaultErrorMessage);
      },
      complete: () => {
      }
    });
  }

  // To remove schedule adhoc message
  onRemoveFromScheduleAdhocMessageConfig() {
    if (!this.selectedAdhocMessageConfig.adhocMessageConfigId) return;
    this.adhocMessageSchedulerService.removeFromScheduleConfig(this.brandCode, this.selectedAdhocMessageConfig.adhocMessageConfigId, this.baseUrl)
    .subscribe({
      next: (response) => {
        this.getAdhocMessageConfigs(this.brandCode, this.baseUrl);
        this.alertTheUser('Successfully removed from schedule adhoc notification configuration ' + this.selectedAdhocMessageConfig.messageConfigName + ' for brand '
              + this.brandCode + '.  Please note that the schedule configuration remains available for a future usage.');
      },
      error: (error) => {
        this.alertTheUser(this.defaultErrorMessage);
      },
      complete: () => {
      }
    });
  }

  // When the user submits a request to save off a new or modified addhoc message config
  onSubmit(event: any) {
    let adhocMessageConfigName = this.adhocMsgConfigForm.value.messageConfigName;
    let adhocMessageConfig: IAdhocMessageConfig = {
      brandCode: this.brandCode,
      messageConfigName: adhocMessageConfigName,
      description: this.adhocMsgConfigForm.value.description,
      filterConfigId: this.adhocMsgConfigForm.value.filterConfigId,
      messageTemplateCode: this.adhocMsgConfigForm.value.messageTemplateCode,
      schedulerConfig: this.adhocMsgConfigForm.value.schedulerConfig ? this.adhocMsgConfigForm.value.schedulerConfig : null,
      modifiedByUser: this.currentUser,
      schedulerStatus: '',
      cronExpression: ''
    }
    if (this.isAdhocMessageConfigNew) {
      adhocMessageConfig.createdByUser = this.currentUser;
      this.adhocMessageConfigService.addAdhocMessageConfig(this.brandCode, adhocMessageConfig, this.baseUrl)
      .subscribe({
        next: (response) => {
          // console.log('onSubmit new - good', response);
          if (response) {
            this.alertTheUser('Successfully created adhoc message config ' + adhocMessageConfigName + ' for brand '
                + this.brandCode);
            this.getAdhocMessageConfigs(this.brandCode, this.baseUrl);
            this.isAdhocMessageConfigDialogVisible = false;
          }
        },
        error: (error) => {
          // console.log('onSubmit new - error', error);
          this.alertTheUser(this.defaultErrorMessage);
        },
        complete: () => {
        }
      });

    } else {
      adhocMessageConfig.adhocMessageConfigId = this.adhocMsgConfigForm.value.adhocMessageConfigId;
      this.adhocMessageConfigService.modifyAdhocMessageConfig(
        this.brandCode, adhocMessageConfig, this.baseUrl)
      .subscribe({
        next: (response) => {
          console.log('onSubmit modify - good', response);
          if (response && response.adhocMessageConfigId) {
            this.alertTheUser('Successfully modified adhoc message config ' + adhocMessageConfigName + ' for brand '
                + this.brandCode);
            this.getAdhocMessageConfigs(this.brandCode, this.baseUrl);
            this.isAdhocMessageConfigDialogVisible = false;
          }
        },
        error: (error) => {
          console.log('onSubmit modify - errorr', error);
                    // this.alertTheUser(error.message);
        },
        complete: () => {
        }
      });
  
    }
  }

  // When the user wants to set up a cron schedule
  onSetScheduleClick() {
    // console.log('onSetScheduleClick', this.adhocMsgConfigForm.value.adhocMessageConfigId);
    this.adhocMsgConfigIdSubject.next(this.adhocMsgConfigForm.value.adhocMessageConfigId | 0);
    this.openCronEditorDialog = true;
  }

  // When the user comes back from the Cron Scheduler dialog with a cron expression
  setCronScheduler(schedulerConfig: string) {
    // console.log('setCron', schedulerConfig);
    if (schedulerConfig !== undefined) {

      if (schedulerConfig == '') {
        // Delete schedule
        this.adhocMsgConfigForm.patchValue({
          'schedulerConfig': null,
          'humanCron': ''
       });
      } else {
        // Updated cron expression
        this.adhocMsgConfigForm.patchValue({
          'schedulerConfig': schedulerConfig,
          'humanCron': 'pending update...' //cronstrue.toString(cronExpression, {locale: 'us'}) 
        });
        // Find out the cron expression based on the given schedulerConfig
        this.adhocMessageSchedulerService.makeCronExpression(
          this.brandCode, schedulerConfig, this.baseUrl)
        .subscribe({
          next: (response) => {
            // console.log('makeCronExpression', response);
            this.adhocMsgConfigForm.patchValue({
              'humanCron': cronstrue.toString(response.message, {locale: 'en', dayOfWeekStartIndexZero: false}) 
            });
          },
          error: (error) => {
          },
          complete: () => {
          }
        });
      }
    }
    this.openCronEditorDialog = false;
  }

  // When the user cancels the dialog for new/modify adhoc message config
  onCancelAdhocMessageConfig() {
    this.isAdhocMessageConfigDialogVisible = false;
  } 
  
  getStatus(isActive: boolean) {
    if (isActive)
      return 'success';
    else
      return 'danger';
  }

  private makeTitleMessage (currentBrand: IBrand) {
    var title = '';
    if (currentBrand.brandCode) {
      title += 'Brand: ' + currentBrand.brandName + ' ( ' + currentBrand.brandCode + ' ) >> ';
    }
    title += 'Target Communications';
    if (currentBrand.logoImageContent) {
      this.brandImage = 'data:' + currentBrand.logoImageType + ';base64,' + currentBrand.logoImageContent;
      this.isImagePreviewShown = true;
    }
    return title;
  }   


  private alertTheUser(message: any) {
    this.confirmationService.confirm({
      message: message,
      header: 'Warning',
      key: 'adhoc-message-config',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "Ok",
      rejectVisible: false,
      acceptButtonStyleClass: "p-button-info p-button-rounded",
      accept: () => {
         return;
       }
    });
  }  
}

