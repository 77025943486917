<div class="grid">

    <div class="col-11">
        <p-card header="Message Executions Log" subheader="" styleClass="ui-card-light" >

            <!--  C R I T E R I A  -->
            <div class="grid">

                <div class="grid col-5">

                    <div class="col-4">
                        <label for="dateFrom" class="margin-right">Date From:</label>
                        <p-calendar id="dateFrom" [(ngModel)]="selectedDateFrom" dateFormat="yy-mm-dd" 
                        [showIcon]="true" class="margin-left"></p-calendar>
                    </div>
    
                    <div class="col-4">
                        <label for="dateTo" class="margin-left">Date To:</label>
                        <p-calendar id="dateTo" [(ngModel)]="selectedDateTo" dateFormat="yy-mm-dd" 
                           [showIcon]="true" class="margin-left"></p-calendar>
                    </div>

                    @if(isBrandSelectionShown) {
                        <div class="col-4">
                            <label for="brandCode">Brand Code:</label>
                            <p-dropdown id ="brandCode" [options]="brandCodes" [(ngModel)]="selectedBrandCode" 
                                (onChange)="onChangeBrand($event)"
                                [editable]="false" class="margin-left" [style]="{'width':'100%'}">
                            </p-dropdown>
                        </div>
                    }
    
                </div>

                <div class="grid col-3">

                    <div class="col-4">
                        <label for="batchType">Batch Type:</label>
                        <p-dropdown id ="batchType" [options]="batchTypes" [(ngModel)]="selectedBatchType"
                            class="margin-left" [style]="{'width':'100%'}" [editable]="false">
                        </p-dropdown>
                    </div>
    
                    <div class="col-4">
                        <label for="executionMethod">Execution Method:</label>
                        <p-dropdown id ="executionMethod" [options]="executionMethods" [(ngModel)]="selectedExecutionMethod"
                            class="margin-left" [style]="{'width':'100%'}" [editable]="false">
                        </p-dropdown>
                    </div>
    
                    <div class="col-4">
                        <label for="statusCode">Status:</label>
                        <p-dropdown id ="statusCode" [options]="statusCodes" [(ngModel)]="selectedStatusCode" 
                            class="margin-left" [style]="{'width':'100%'}" [editable]="false">
                        </p-dropdown>
                    </div>
    
                </div>

                <div class="grid col-4">

                    <div class="col-2"></div>

                    <div class="col-8">
                        <p-button label="Search" styleClass="p-button-success p-button-raised p-button-rounded margin-left" (click)="onSearchClick($event)"></p-button>
                        <!-- <p-button label="Next" styleClass="p-button-info p-button-raised p-button-rounded margin-left" (click)="isNextButtonShown($event)"></p-button> -->
                        <p-button label="Clear" styleClass="p-button-danger p-button-raised p-button-rounded margin-left" (click)="onClearClick($event)"></p-button>
                    </div>
    
                    <div class="col-2" id="idSpinner" [hidden]="isSpinnerHidden">
                    <div class="loader"></div>  
                    </div>
    
                </div>


            </div>  

            <!--  G R I D  -->
            
            <div class="grid">

                <p-table #dtMessageBatchExecs [columns]="messageBatchExecsCols" [value]="messageBatchExecs" selectionMode="single" 
                    [(selection)]="selectedMeaagesgBatchExec" styleClass="p-datatable-striped p-datatable-sm"
                     [rowsPerPageOptions]="[10,15,25,50]" [showCurrentPageReport]="true"
                    sortMode="multiple" (onRowSelect)="onMessageBatchExecRowSelect($event)" [paginator]="false" [rows]="25">
                    <ng-template pTemplate="caption">
                    Log of Message Batch Executions
                    </ng-template>
                    <ng-template pTemplate="caption">
                    <div style="text-align: right">        
                        <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                        <input type="text" pInputText size="50" placeholder="Global Filter" (input)="dtMessageBatchExecs.filterGlobal($any($event.target).value, 'contains')" style="width:auto">
                    </div>
                </ng-template>
                    <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th *ngFor="let col of columns" [pSortableColumn]="col.field" class="p-column-title" [ngStyle]="{'width': col.width, 'display': col.display}">
                        {{col.header}}
                        <!-- <p-sortIcon [field]="col.field"></p-sortIcon> -->
                        </th>
                    </tr>
                </ng-template>
                    <ng-template pTemplate="body" let-rowData let-columns="columns">
                    <tr [pSelectableRow]="rowData">
                        <td *ngFor="let col of columns" [ngStyle]="{'width': col.width, 'display': col.display}">
                            {{rowData[col.field]}}
                        </td>
                    </tr>
                </ng-template>

                <ng-template pTemplate="summary">
                    <div style="text-align:left">
                        <div class="ui-helper-clearfix">
                            <p-button label="Prior" *ngIf="isPriorButtonShown" styleClass="p-button-info p-button-raised p-button-rounded margin-left" (click)="onPriorClick($event)"></p-button>
                            <p-button label="Next"  *ngIf="isNextButtonShown" styleClass="p-button-info p-button-raised p-button-rounded margin-left" (click)="onNextClick($event)"></p-button>
                            <!-- <p-button styleClass="p-button-info p-button-raised p-button-rounded margin-left"
                                label="Export" (click)="onFileCreationRequest($event)" style="float:right">
                            </p-button> -->
                        </div>
                    </div>
                </ng-template>    


                </p-table> 

            </div>

            <p-footer>
                <mat-icon class="nav-caption wal-center">account_balance</mat-icon>
            </p-footer>
        </p-card>
    </div>            

</div>    

<p-confirmDialog class="lf-confirm-dialog" appendTo="body" key="message-exec-viewer"></p-confirmDialog>
