import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { MessageService } from '../message.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpParams } from "@angular/common/http";
import { BrandConfigPropertySrv } from '../../models/brand.config.property.srv';
import { IBrandConfigProperty } from '../../interfaces/brand-config-property';
import { IBrandConfigPropertyResponse } from '../../interfaces/brand-config-property-response';
//import { AppConfigService} from '../app-config.service';
import { environment } from '../../../environments/environment';
import { ErrorProcessorService }  from "../error-processor.service";

@Injectable({
  providedIn: 'root'
})
export class BrandConfigPropertyService {

  // protected basicAuth = AppConfigService.settings.appSetting.baseAuth;
  protected basicAuth = environment.appSetting.baseAuth;

  private brandConfigPropertiesUrl = '/config-props-admin/brands/{brandCode}/brand-config-properties';
  private brandConfigPropertyUrl = '/config-props-admin/brands/{brandCode}/brand-config-properties/{brandConfigPropertyId}';

  private headers = new HttpHeaders()
    .set('Authorization', this.basicAuth)

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private errorProcessorService: ErrorProcessorService) { }

  getBrandConfigProperties(baseUrl: string, brandCode: string): 
      Observable<IBrandConfigPropertyResponse> {
    var params = new HttpParams();
    // params = params.set('enabled', 'true');
    params = params.set('limit', '100');
    var url = baseUrl + this.brandConfigPropertiesUrl
      .replace('{brandCode}', brandCode);
    return this.http.get<IBrandConfigPropertyResponse>(url, {params: params, responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  getBrandConfigProperty(brandCode: string, brandConfigPropertyId: number, baseUrl: string): 
      Observable<IBrandConfigProperty> {
    var url = baseUrl + this.brandConfigPropertyUrl
      .replace('{brandCode}', brandCode)
      .replace('{brandConfigPropertyId}', brandConfigPropertyId.toString());
    return this.http.get<IBrandConfigProperty>(url, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  addBrandConfigProperty(baseUrl: string, brandConfigProperty: any) {
    let newBrandConfigProperty: BrandConfigPropertySrv = 
    {
      brandConfigPropertyId: 0, 
      propertyName: brandConfigProperty.propertyName, 
      propertyDescription: brandConfigProperty.propertyDescription, 
      propertyValue: brandConfigProperty.propertyValue, 
      enabled: brandConfigProperty.enabled ? 1 : 0, 
      brandCode: brandConfigProperty.brandCode
    };
    let newBrandConfigProperties = [newBrandConfigProperty];
    var url = baseUrl + this.brandConfigPropertiesUrl
      .replace('{brandCode}', brandConfigProperty.brandCode);
    return this.http.post(url, newBrandConfigProperties, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

   modifyBrandConfigProperty(baseUrl: string, brandConfigProperty: any) {
    let modifiedBrandConfigProperty: BrandConfigPropertySrv = 
    {
      brandConfigPropertyId: brandConfigProperty.brandConfigPropertyId, 
      propertyName: brandConfigProperty.propertyName, 
      propertyDescription: brandConfigProperty.propertyDescription, 
      propertyValue: brandConfigProperty.propertyValue, 
      enabled: brandConfigProperty.enabled ? 1 : 0, 
      brandCode: brandConfigProperty.brandCode
    };
    let modifiedBrandConfigProperties = [modifiedBrandConfigProperty];
    var url = baseUrl + this.brandConfigPropertiesUrl
      .replace('{brandCode}', brandConfigProperty.brandCode);
    return this.http.put(url, modifiedBrandConfigProperties, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }

  deleteBrandConfigProperty(baseUrl: string, brandCode: string, brandConfigPropertyId: number) {
    var url = baseUrl + this.brandConfigPropertyUrl
      .replace('{brandCode}', brandCode)
      .replace('{brandConfigPropertyId}', brandConfigPropertyId.toString());
    return this.http.delete(url, {responseType: 'json'})
    .pipe(
      catchError(this.errorProcessorService.handleError)
    )
  }
  


}
