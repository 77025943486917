export default class Utils {

    // This returns a range of numbers. Starts from 0 if 'startFrom' is not set 
    public static getRange(startFrom: number, until: number) {
        return Array.from({ length: (until + 1 - startFrom) }, (_, k) => k + startFrom);
    }

    // This returns a range of paddded numbers. Starts from 0 if 'startFrom' is not set 
    public static getPaddedRange(startFrom: number, until: number) {
        return Array.from({ length: (until + 1 - startFrom) }, (_, k) => (k + startFrom).toString().padStart(2, '0'));
    }

    public static getWeekDays() {
        return [
            {dayNum: 1, dayName: 'Sunday'},
            {dayNum: 2, dayName: 'Monday'},
            {dayNum: 3, dayName: 'Tuesday'},
            {dayNum: 4, dayName: 'Wednesday'},
            {dayNum: 5, dayName: 'Thursday'},
            {dayNum: 6, dayName: 'Friday'},
            {dayNum: 7, dayName: 'Saturday'}
        ]
    }

    public static getOrderedDays() {
        return [
            {dayNum: 1, dayName: '1st'}, {dayNum: 2, dayName: '2nd'}, {dayNum: 3, dayName: '3rd'}, {dayNum: 4, dayName: '4th'},
            {dayNum: 5, dayName: '5th'}, {dayNum: 6, dayName: '6th'}, {dayNum: 7, dayName: '7th'}, {dayNum: 8, dayName: '8th'},
            {dayNum: 9, dayName: '9th'}, {dayNum: 10, dayName: '10th'}, {dayNum: 11, dayName: '11th'}, {dayNum: 12, dayName: '12th'},
            {dayNum: 13, dayName: '13th'}, {dayNum: 14, dayName: '14th'}, {dayNum: 15, dayName: '15th'}, {dayNum: 16, dayName: '16th'},
            {dayNum: 17, dayName: '17th'}, {dayNum: 18, dayName: '18th'}, {dayNum: 19, dayName: '19th'}, 
            {dayNum: 20, dayName: '20th'}, {dayNum: 21, dayName: '21th'}, {dayNum: 22, dayName: '22th'}, {dayNum: 23, dayName: '23th'},
            {dayNum: 24, dayName: '24th'}, {dayNum: 25, dayName: '25th'}, {dayNum: 26, dayName: '26th'}, {dayNum: 27, dayName: '27th'},
            {dayNum: 28, dayName: '28th'}, {dayNum: 29, dayName: '29th'}, {dayNum: 30, dayName: '30th'}, {dayNum: 31, dayName: '31th'}
        ]
    }

    public static getTagIntervals(from: number, to: number) {
        let arr: any[] = [];
        Utils.getRange(from, to).forEach(item => {
            arr.push({value: item, tag: item.toString().padStart(2, '0')})
        });
        return arr;
    }

    public static getTagMonths() {
        return [
            {value: 1, tag: 'JAN'}, {value: 2, tag: 'FEB'}, {value: 3, tag: 'MAR'}, {value: 4, tag: 'APR'},
            {value: 5, tag: 'MAY'}, {value: 6, tag: 'JUN'}, {value: 7, tag: 'JUL'}, {value: 8, tag: 'AUG'},
            {value: 9, tag: 'SEP'}, {value: 10, tag: 'OCT'}, {value: 11, tag: 'NOV'}, {value: 12, tag: 'DEC'}
        ]
    }    
    
    public static isSpecificIntervalSet(specificIntervals: bigint, interval: number): boolean {
        return (specificIntervals & (1n << BigInt(interval))) > 0;
    }

    public static setSpecificInterval(specificIntervals: bigint, interval: number): bigint {
        return specificIntervals |= (1n << BigInt(interval));
    }

    public static clearSpecificInterval(specificIntervals: bigint, interval: number): bigint {
        return specificIntervals &= ~(1n << BigInt(interval));
    }
    
    public static setSpecificOptions(specificIntervals: bigint, specificOptions: any[]): bigint {
        specificOptions.forEach(option => {
            if (option.checked) {
                specificIntervals = this.setSpecificInterval(specificIntervals, option.value);
            } else {
                specificIntervals = this.clearSpecificInterval(specificIntervals, option.value);
            }
        })
        return specificIntervals;
    }

    public static setBasedSpecificOptions(specificIntervals: bigint, specificOptions: any[], base: number): bigint {
        specificOptions.forEach(option => {
            if (option.checked) {
                specificIntervals = this.setSpecificInterval(specificIntervals, option.value - base);
            } else {
                specificIntervals = this.clearSpecificInterval(specificIntervals, option.value - base);
            }
        })
        return specificIntervals;
    }

    // public static isSpecificIntervalSet(intervalType: string, specificIntervals: bigint, interval: number): boolean {
    //     if (interval < 0 || interval > this.findMaxInterval(intervalType)) {
    //         false;
    //     }
    //     return (specificIntervals & (1n << BigInt(interval))) > 0;
    // }

    // public static setSpecificInterval(intervalType: string, specificIntervals: bigint, interval: number): bigint {
    //     if (interval < 0 || interval > this.findMaxInterval(intervalType)) {
    //         return specificIntervals;
    //     } else {
    //         return specificIntervals |= (1n << BigInt(interval));
    //     }
    // }

    // public static clearSpecificInterval(intervalType: string, specificIntervals: bigint, interval: number): bigint {
    //     if (interval < 0 || interval > this.findMaxInterval(intervalType)) {
    //         return specificIntervals;
    //     } else {
    //          return specificIntervals &= ~(1n << BigInt(interval));
    //     }
    // }    

    // private static findMaxInterval(intervalType: string): number {
    //     let maxInterval: number = 0;
    //     switch (intervalType) {
    //         case 'second':
    //         case 'minute':
    //             maxInterval = 59;
    //             break;
    //         case 'hour':
    //             maxInterval = 23;
    //             break;
    //         case 'year':
    //             maxInterval = 19;
    //             break;
    //         }
    //     return maxInterval;
    // }

    // public static isSpecificHourSet(specificHours: bigint, hour: number): boolean {
    //     if (hour < 0 || hour > 23) {
    //         false;
    //     }
    //     return (specificHours & (1n << BigInt(hour))) > 0;
    // }
    
    // public static setSpecificHour(specificHours: bigint, hour: number): bigint {
    //     if (hour < 0 || hour > 23) {
    //         return specificHours;
    //     } else {
    //         return specificHours |= (1n << BigInt(hour));
    //     }
    // }

    // public static clearSpecificHour(specificHours: bigint, hour: number): bigint {
    //     if (hour < 0 || hour > 23) {
    //         return specificHours;
    //     } else {
    //          return specificHours &= ~(1n << BigInt(hour));
    //     }
    // }    

    // public static isSpecificMinuteSet(specificMinutes: bigint, minute: number): boolean {
    //     console.log(specificMinutes, minute);
    //     if (minute < 0 || minute > 59) {
    //         false;
    //     }
    //     return (specificMinutes & (1n << BigInt(minute))) > 0;
    // }
    
    // public static setSpecificMinute(specificMinutes: bigint, minute: number): bigint {
    //     if (minute < 0 || minute > 59) {
    //         return specificMinutes;
    //     } else {
    //         return specificMinutes |= (1n << BigInt(minute));
    //     }
    // }

    // public static clearSpecificMinute(specificMinutes: bigint, minute: number): bigint {
    //     if (minute < 0 || minute > 59) {
    //         return specificMinutes;
    //     } else {
    //          return specificMinutes &= ~(1n << BigInt(minute));
    //     }
    // }    

    // public static isSpecificSecondSet(specificSeconds: bigint, second: number): boolean {
    //     if (second < 0 || second > 59) {
    //         false;
    //     }
    //     return (specificSeconds & (1n << BigInt(second))) > 0;
    // }
    
    // public static setSpecificSecond(specificSeconds: bigint, second: number): bigint {
    //     if (second < 0 || second > 59) {
    //         return specificSeconds;
    //     } else {
    //         return specificSeconds |= (1n << BigInt(second));
    //     }
    // }

    // public static clearSpecificSecond(specificSeconds: bigint, second: number): bigint {
    //     if (second < 0 || second > 59) {
    //         return specificSeconds;
    //     } else {
    //          return specificSeconds &= ~(1n << BigInt(second));
    //     }
    // }    

    public static toHex(num: number): string {
        const map = "0123456789abcdef";
        let hex = num === 0 ? "0" : "";
        while (num !== 0) {
            hex = map[num & 15] + hex;
            num = num >>> 4;
        }
        return hex;
    }

}