<p-dialog  header="Cron Editor" [(visible)]="displayCronEditorDialog" [focusOnShow]="false" [responsive]="true"
    showEffect="fade" [modal]="true" [style]="{width: '55%'}" position="top"  [showHeader]="true" [closable]="false">

    <p-card header="Scheduler Configuration" subheader="" styleClass="ui-card-light">


        <!-- <div> -->


            <p-tabView  orientation="top">

                <!--  S E C O N D S  -->
            
                <p-tabPanel header="Seconds" >

                    <p-card header="Seconds" subheader="" styleClass="ui-card-light">

                        <div class="form-check mb-3">
                            <p-radioButton name="cronSecond" value="EVERY_SECOND" [(ngModel)]="schedulerConfig.secondsConfig.mode" styleClass="radio-style"/>
                            <label for="cronEverySecond" class="ml-4">Every second</label>
                        </div>
            
                        <div class="form-check mb-3">
                            <p-radioButton name="cronSecond" value="EVERY_N_SECONDS_STARTING_AT" [(ngModel)]="schedulerConfig.secondsConfig.mode" styleClass="radio-style"/>
                            <label for="cronSecondIncrement" class="ml-4">Every</label>
                            <select class="seconds" class="ml-4" style="width:50px"
                                [(ngModel)]="schedulerConfig.secondsConfig.everyNSeconds" >
                                <option *ngFor="let second of selectOptions.seconds" [ngValue]="second">
                                    {{second}}
                                </option>
                            </select>
                            <label class="ml-4">second(s) starting at second</label>
                            <select class="seconds" class="ml-4" style="width:50px"
                                [(ngModel)]="schedulerConfig.secondsConfig.startingSecond" >
                                <option *ngFor="let second of selectOptions.tagSeconds" [ngValue]="second.value">
                                    {{second.tag}}
                                </option>
                            </select>
                        </div>
            
                        <div class="form-check mb-3">
                            <p-radioButton name="cronSecond" value="SPECIFIC_SECONDS" [(ngModel)]="schedulerConfig.secondsConfig.mode" styleClass="radio-style"/>
                            <label for="cronEverySecond" class="ml-4">Specific second (choose one or many)</label>
                            <div *ngFor="let r of specificSecondRows" style="display:flex;" class="ml-6">
                                <div *ngFor="let option of specificSecondOptions  | slice:(r*10):(10*(r+1));">
                                    <div style="height: 25px;">
                                        <label>
                                            <input type="checkbox" name="options" value="{{option.value}}" [(ngModel)]="option.checked" class="ml-3"/>
                                            {{option.name}}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
            
                        <div class="form-check mb-3">
                            <p-radioButton name="cronSecond" value="EVERY_SECOND_IN_RANGE" [(ngModel)]="schedulerConfig.secondsConfig.mode" styleClass="radio-style"/>
                            <label for="cronMinuteIncrement" class="ml-4">Every second between second</label>
                            <select class="seconds" class="ml-4" style="width:50px"
                                [(ngModel)]="schedulerConfig.secondsConfig.rangeFrom" >
                                <option *ngFor="let second of selectOptions.tagSeconds" [ngValue]="second.value">
                                    {{second.tag}}
                                </option>
                            </select>
                            <label class="ml-4">and second</label>
                            <select class="seconds" class="ml-4" style="width:50px"
                                [(ngModel)]="schedulerConfig.secondsConfig.rangeTo" >
                                <option *ngFor="let second of selectOptions.tagSeconds" [ngValue]="second.value">
                                    {{second.tag}}
                                </option>
                            </select>
                        </div>
    
                    </p-card>

                </p-tabPanel>            

                <!--  M I N U T E S  -->

                <p-tabPanel header="Minutes">

                    <p-card header="Minutes" subheader="" styleClass="ui-card-light">

                        <div class="form-check mb-3">
                            <p-radioButton name="cronMinute" value="EVERY_MINUTE" [(ngModel)]="schedulerConfig.minutesConfig.mode" styleClass="radio-style"/>
                            <label for="cronEveryMinute" class="ml-4">Every minute</label>
                        </div>
            
                        <div class="form-check mb-3">
                            <p-radioButton name="cronMinute" value="EVERY_N_MINUTES_STARTING_AT" [(ngModel)]="schedulerConfig.minutesConfig.mode" styleClass="radio-style"/>
                            <label for="cronMinuteIncrement" class="ml-4">Every</label>
                            <select class="minutes" class="ml-4" style="width:50px"
                                [(ngModel)]="schedulerConfig.minutesConfig.everyNMinutes" >
                                <option *ngFor="let minute of selectOptions.minutes" [ngValue]="minute">
                                    {{minute}}
                                </option>
                            </select>
                            <label class="ml-4">minute(s) starting at minute</label>
                            <select class="minutes" class="ml-4" style="width:50px"
                                [(ngModel)]="schedulerConfig.minutesConfig.startingMinute" >
                                <option *ngFor="let minute of selectOptions.tagMinutes" [ngValue]="minute.value">
                                    {{minute.tag}}
                                </option>
                            </select>
                        </div>
            
                        <div class="form-check mb-3">
                            <p-radioButton name="cronMinute" value="SPECIFIC_MINUTES" [(ngModel)]="schedulerConfig.minutesConfig.mode" styleClass="radio-style"/>
                            <label for="cronEveryMinute" class="ml-4">Specific minute (choose one or many)</label>
                            <div *ngFor="let r of specificMinuteRows" style="display:flex;" class="ml-6">
                                <div *ngFor="let option of specificMinuteOptions  | slice:(r*10):(10*(r+1));">
                                    <div style="height: 25px;">
                                        <label>
                                            <input type="checkbox" name="options" value="{{option.value}}" [(ngModel)]="option.checked" class="ml-3"/>
                                            {{option.name}}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
            
                        <div class="form-check mb-3">
                            <p-radioButton name="cronMinute" value="EVERY_MINUTE_IN_RANGE" [(ngModel)]="schedulerConfig.minutesConfig.mode" styleClass="radio-style"/>
                            <label for="cronMinuteIncrement" class="ml-4">Every minute between minute</label>
                            <select class="minutes" class="ml-4" style="width:50px"
                                [(ngModel)]="schedulerConfig.minutesConfig.rangeFrom" >
                                <option *ngFor="let minute of selectOptions.tagMinutes" [ngValue]="minute.value">
                                    {{minute.tag}}
                                </option>
                            </select>
                            <label class="ml-4">and minute</label>
                            <select class="minutes" class="ml-4" style="width:50px"
                                [(ngModel)]="schedulerConfig.minutesConfig.rangeTo" >
                                <option *ngFor="let minute of selectOptions.tagMinutes" [ngValue]="minute.value">
                                    {{minute.tag}}
                                </option>
                            </select>
                        </div>
    
                    </p-card>

                </p-tabPanel>            

                <!--  H O U R S  -->

                <p-tabPanel header="Hours">

                    <p-card header="Hours" subheader="" styleClass="ui-card-light">

                        <div class="form-check mb-3">
                            <p-radioButton name="cronHour" value="EVERY_HOUR" [(ngModel)]="schedulerConfig.hoursConfig.mode" styleClass="radio-style"/>
                            <label for="cronEveryHour" class="ml-4">Every hour</label>
                        </div>
            
                        <div class="form-check mb-3">
                            <p-radioButton name="cronHour" value="EVERY_N_HOURS_STARTING_AT" [(ngModel)]="schedulerConfig.hoursConfig.mode" styleClass="radio-style"/>
                            <label for="cronHourIncrement" class="ml-4">Every</label>
                            <select class="hours" class="ml-4" style="width:50px"
                                [(ngModel)]="schedulerConfig.hoursConfig.everyNHours" >
                                <option *ngFor="let hour of selectOptions.hours" [ngValue]="hour">
                                    {{hour}}
                                </option>
                            </select>
                            <label class="ml-4">hour(s) starting at hour</label>
                            <select class="hours" class="ml-4" style="width:50px"
                                [(ngModel)]="schedulerConfig.hoursConfig.startingHour" >
                                <option *ngFor="let hour of selectOptions.tagHours" [ngValue]="hour.value">
                                    {{hour.tag}}
                                </option>
                            </select>
                        </div>
            
                        <div class="form-check mb-3">
                            <p-radioButton name="cronHour" value="SPECIFIC_HOURS" [(ngModel)]="schedulerConfig.hoursConfig.mode" styleClass="radio-style"/>
                            <label for="cronEveryHour" class="ml-4">Specific hour (choose one or many)</label>
                            <div *ngFor="let r of specificHourRows" style="display:flex;" class="ml-6">
                                <div *ngFor="let option of specificHourOptions  | slice:(r*10):(10*(r+1));">
                                    <div style="height: 25px;">
                                        <label>
                                            <input type="checkbox" name="options" value="{{option.value}}" [(ngModel)]="option.checked" class="ml-3"/>
                                            {{option.name}}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
            
                        <div class="form-check mb-3">
                            <p-radioButton name="cronHour" value="EVERY_HOUR_IN_RANGE" [(ngModel)]="schedulerConfig.hoursConfig.mode" styleClass="radio-style"/>
                            <label for="cronHourIncrement" class="ml-4">Every hour between hour</label>
                            <select class="hours" class="ml-4" style="width:50px"
                                [(ngModel)]="schedulerConfig.hoursConfig.rangeFrom" >
                                <option *ngFor="let hour of selectOptions.tagHours" [ngValue]="hour.value">
                                    {{hour.tag}}
                                </option>
                            </select>
                            <label class="ml-4">and hour</label>
                            <select class="hours" class="ml-4" style="width:50px"
                                [(ngModel)]="schedulerConfig.hoursConfig.rangeTo" >
                                <option *ngFor="let hour of selectOptions.tagHours" [ngValue]="hour.value">
                                    {{hour.tag}}
                                </option>
                            </select>
                        </div>

                    </p-card>
        
                </p-tabPanel>            

                <!--  D A Y  -->

                <p-tabPanel header="Day">

                    <p-card header="Day" subheader="" styleClass="ui-card-light">

                        <div class="form-check mb-3">
                            <p-radioButton name="cronDay" value="EVERY_DAY" [(ngModel)]="schedulerConfig.daysConfig.mode" styleClass="radio-style"/>
                            <label for="cronEveryDay" class="ml-4">Every day</label>
                        </div>
<!-- 
                        <div class="form-check mb-3">
                            <p-radioButton name="cronDay" value="EVERY_N_DAYS_STARTING_ON_DAY_OF_WEEK" [(ngModel)]="schedulerConfig.daysConfig.mode" styleClass="radio-style"/>
                            <label for="cronDayIncrementForWeekday" class="ml-4">Every</label>
                            <select class="days" class="ml-4" style="width:50px"
                                [(ngModel)]="schedulerConfig.daysConfig.everyNDaysOfWeek" >
                                <option *ngFor="let day of selectOptions.days" [ngValue]="day">
                                    {{day}}
                                </option>
                            </select>
                            <label class="ml-4">day(s) starting on</label>
                            <select class="days" class="ml-4" style="width:100px"
                                [(ngModel)]="schedulerConfig.daysConfig.startingDayOfWeek" >
                                <option *ngFor="let day of selectOptions.weekDays" [ngValue]="day.dayNum">
                                    {{day.dayName}}
                                </option>
                            </select>
                        </div>
            
                        <div class="form-check mb-3">
                            <p-radioButton name="cronDay" value="EVERY_N_DAYS_STARTING_ON_DAY_OF_MONTH" [(ngModel)]="schedulerConfig.daysConfig.mode" styleClass="radio-style"/>
                            <label for="cronDayIncrementForMonth" class="ml-4">Every</label>
                            <select class="days" class="ml-4" style="width:50px"
                                [(ngModel)]="schedulerConfig.daysConfig.everyNDaysOfMonth" >
                                <option *ngFor="let day of selectOptions.allDays" [ngValue]="day">
                                    {{day}}
                                </option>
                            </select>
                            <label class="ml-4">day(s) starting on</label>
                            <select class="days" class="ml-4" style="width:50px"
                                [(ngModel)]="schedulerConfig.daysConfig.startingDayOfMonth" >
                                <option *ngFor="let day of selectOptions.orderedDays" [ngValue]="day.dayNum">
                                    {{day.dayName}}
                                </option>
                            </select>
                        </div>
 -->
                        <div class="form-check mb-3">
                            <p-radioButton name="cronDay" value="SPECIFIC_DAYS_OF_WEEK" [(ngModel)]="schedulerConfig.daysConfig.mode" styleClass="radio-style"/>
                            <label for="cronSpecificDaysofWeek" class="ml-4">Specific day of the week (choose one or many)</label>
                            <div *ngFor="let r of specificDayOfWeekRows" style="display:flex;" class="ml-6">
                                <div *ngFor="let option of specificDayOfWeekOptions  | slice:(r*7):(7*(r+1));">
                                    <label>
                                        <input type="checkbox" name="options" value="{{option.value}}" [(ngModel)]="option.checked" class="ml-3"/>
                                        {{option.name}}
                                    </label>
                                </div>
                            </div>
                        </div>                        

                        <div class="form-check mb-3">
                            <p-radioButton name="cronDay" value="SPECIFIC_DAYS_OF_MONTH" [(ngModel)]="schedulerConfig.daysConfig.mode" styleClass="radio-style"/>
                            <label for="cronSpecificDaysofWeek" class="ml-4">Specific day of month (choose one or many)</label>
                            <div *ngFor="let r of specificDayOfMonthRows" style="display:flex;" class="ml-6">
                                <div *ngFor="let option of specificDayOfMonthOptions  | slice:(r*10):(10*(r+1));">
                                    <div style="height: 25px;">
                                        <label>
                                            <input type="checkbox" name="options" value="{{option.value}}" [(ngModel)]="option.checked" class="ml-3"/>
                                            {{option.name}}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>                        

                    </p-card>

                </p-tabPanel>            

                <!--  M O N T H  -->

                <p-tabPanel header="Month">

                    <p-card header="Month" subheader="" styleClass="ui-card-light">

                        <div class="form-check mb-3">
                            <p-radioButton name="cronMonth" value="EVERY_MONTH" [(ngModel)]="schedulerConfig.monthsConfig.mode" styleClass="radio-style"/>
                            <label for="cronEveryMonth" class="ml-4">Every month</label>
                        </div>
<!--             
                        <div class="form-check mb-3">
                            <p-radioButton name="cronMonth" value="EVERY_N_MONTHS_STARTING_AT" [(ngModel)]="schedulerConfig.monthsConfig.mode" styleClass="radio-style"/>
                            <label for="cronMonthIncrement" class="ml-4">Every</label>
                            <select class="months" class="ml-4" style="width:50px"
                                [(ngModel)]="schedulerConfig.monthsConfig.everyNMonths" >
                                <option *ngFor="let month of selectOptions.months" [ngValue]="month">
                                    {{month}}
                                </option>
                            </select>
                            <label class="ml-4">month(s) starting at month</label>
                            <select class="months" class="ml-4" style="width:60px"
                                [(ngModel)]="schedulerConfig.monthsConfig.startingMonth" >
                                <option *ngFor="let month of selectOptions.tagMonths" [ngValue]="month.value">
                                    {{month.tag}}
                                </option>
                            </select>
                        </div>
             -->
                        <div class="form-check mb-3">
                            <p-radioButton name="cronMonth" value="SPECIFIC_MONTHS" [(ngModel)]="schedulerConfig.monthsConfig.mode" styleClass="radio-style"/>
                            <label for="cronEveryMonth" class="ml-4">Specific month (choose one or many)</label>
                            <div *ngFor="let r of specificMonthRows" style="display:flex;" class="ml-6">
                                <div *ngFor="let option of specificMonthOptions  | slice:(r*12):(12*(r+1));">
                                    <div style="height: 25px;">
                                        <label>
                                            <input type="checkbox" name="options" value="{{option.value}}" [(ngModel)]="option.checked" class="ml-3"/>
                                            {{option.name}}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
            
                        <div class="form-check mb-3">
                            <p-radioButton name="cronMonth" value="EVERY_MONTH_IN_RANGE" [(ngModel)]="schedulerConfig.monthsConfig.mode" styleClass="radio-style"/>
                            <label for="cronMonthIncrement" class="ml-4">Every month between month</label>
                            <select class="months" class="ml-4" style="width:60px"
                                [(ngModel)]="schedulerConfig.monthsConfig.rangeFrom" >
                                <option *ngFor="let month of selectOptions.tagMonths" [ngValue]="month.value">
                                    {{month.tag}}
                                </option>
                            </select>
                            <label class="ml-4">and month</label>
                            <select class="months" class="ml-4" style="width:60px"
                                [(ngModel)]="schedulerConfig.monthsConfig.rangeTo" >
                                <option *ngFor="let month of selectOptions.tagMonths" [ngValue]="month.value">
                                    {{month.tag}}
                                </option>
                            </select>
                        </div>
    
                    </p-card>

                </p-tabPanel>            

                <!--  Y E A R  -->

                <p-tabPanel header="Year">

                    <p-card header="Years" subheader="" styleClass="ui-card-light">

                        <div class="form-check mb-3">
                            <p-radioButton name="cronYear" value="EVERY_YEAR" [(ngModel)]="schedulerConfig.yearsConfig.mode" styleClass="radio-style"/>
                            <label for="cronAnyYear" class="ml-4">Any year</label>
                        </div>
<!--             
                        <div class="form-check mb-3">
                            <p-radioButton name="cronYear" value="EVERY_N_YEARS_STARTING_AT" [(ngModel)]="schedulerConfig.yearsConfig.mode" styleClass="radio-style"/>
                            <label for="cronYearIncrement" class="ml-4">Every</label>
                            <select class="years" class="ml-4" style="width:50px"
                                [(ngModel)]="schedulerConfig.yearsConfig.everyNYears" >
                                <option *ngFor="let year of selectOptions.hours" [ngValue]="year">
                                    {{year}}
                                </option>
                            </select>
                            <label class="ml-4">year(s) starting at year</label>
                            <select class="years" class="ml-4" style="width:60px"
                                [(ngModel)]="schedulerConfig.yearsConfig.startingYear" >
                                <option *ngFor="let year of selectOptions.years" [ngValue]="year">
                                    {{year}}
                                </option>
                            </select>
                        </div>
             -->
                        <div class="form-check mb-3">
                            <p-radioButton name="cronYear" value="SPECIFIC_YEARS" [(ngModel)]="schedulerConfig.yearsConfig.mode" styleClass="radio-style"/>
                            <label for="cronSpecificYear" class="ml-4">Specific year (choose one or many)</label>
                            <div *ngFor="let r of specificYearRows" style="display:flex;" class="ml-6">
                                <div *ngFor="let option of specificYearOptions  | slice:(r*5):(5*(r+1));">
                                    <div style="height: 25px;">
                                        <label>
                                            <input type="checkbox" name="options" value="{{option.value}}" [(ngModel)]="option.checked" class="ml-3"/>
                                            {{option.name}}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
            
                        <div class="form-check mb-3">
                            <p-radioButton name="cronYear" value="EVERY_YEAR_IN_RANGE" [(ngModel)]="schedulerConfig.yearsConfig.mode" styleClass="radio-style"/>
                            <label for="cronYearIncrement" class="ml-4">Every year between </label>
                            <select class="years" class="ml-4" style="width:60px"
                                [(ngModel)]="schedulerConfig.yearsConfig.rangeFrom" >
                                <option *ngFor="let year of selectOptions.years" [ngValue]="year">
                                    {{year}}
                                </option>
                            </select>
                            <label class="ml-4">and</label>
                            <select class="years" class="ml-4" style="width:60px"
                                [(ngModel)]="schedulerConfig.yearsConfig.rangeTo" >
                                <option *ngFor="let year of selectOptions.years" [ngValue]="year">
                                    {{year}}
                                </option>
                            </select>
                        </div>

                    </p-card>

                </p-tabPanel>            


            </p-tabView>  

        <!-- </div> -->


        <p-footer>

            <div class="col-12">
              <div  class="col-2" class="row container-fluid"  id="divshow">
                  <p-button type="button" label="Ok" styleClass="p-button-success p-button-raised p-button-rounded margin-left" (click)="onSubmitCronEditor()" ></p-button>
                  <p-button type="button" label="Delete" styleClass="p-button-danger p-button-raised p-button-rounded margin-left" (click)="onDeleteCronEditor()"></p-button>
                  <p-button type="button" label="Cancel" styleClass="p-button-primary p-button-raised p-button-rounded margin-left" (click)="onCancelCronEditor()"></p-button>
              </div> 
              <div class="col-12">
                  <mat-label>{{hintMsg}}</mat-label>
              </div>
            </div>
  
        </p-footer>
        
    </p-card>            
  
  </p-dialog>
  
  <p-confirmDialog class="lf-confirm-dialog" appendTo="body" key="cron-editor"></p-confirmDialog>        
