export const environment = {
    production: false,
    env: {
        name: "DEMO",
        banner: "Demo Environment"
    },
    apiServer:  {
        webSocketlUrl: "wss://portalserver-demo.acimobills.com/portal",
        portalUrl: "https://portalserver-demo.acimobills.com/portal/api",
        authServerUrl: "https://portalserver-demo.acimobills.com/auth/api",
        rtpnUrl: "https://demo.walletron.com/rtpn/api"         
    },
    appSetting: {
        baseAuth: "Basic YWRtaW46d2FsbGV0cm9uZ2Y=",
        portalAuth: "Basic dXNyX21vbml0b3I6ZmI2ZW54RVRVUktnNk54Nw==",
        communicationAuth: "Basic dXNyX2NvbW1fbW5ncjpwc3dkX3Vzcl9jb21tX21uZ3I="
    },
    brandMigration: {
        sourceRegions:  ["uat", "client2prod", "mbprod", "mobills2prod", "wuprod"],
        targetRegions: ["demo"]
    }
};