import { Component, OnInit, ViewChild } from '@angular/core';
import { trigger, transition, style, animate, query, stagger, group, state } from '@angular/animations';
import { Validators, FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { ConfirmationService } from "primeng/api";
import { IBrand } from '../../interfaces/brand';
import { IBrandConfigProperty } from '../../interfaces/brand-config-property';
import { ConfigProperty } from '../../models/config.property';
import { IEnvRegion } from '../../interfaces/env-region';
import { ConfigPropertyService } from '../../services/config/config-property.service';
import { BrandConfigPropertyService } from '../../services/config/brand-config-property.service';
import { SharedService } from "../../services/shared.service";
import { UserContextService } from '../../services/safeguard/user-context.service';
import { BrandAdminHeaderComponent } from '../../components/brand-admin-header/brand-admin-header.component';

@Component({
  selector: 'app-brand-config-property',
  templateUrl: './brand-config-property.component.html',
  styleUrls: ['./brand-config-property.component.css'],
  animations: [
    trigger('flyInOut', [
      state('in', style({ transform: 'translateX(0)' })),
      transition('void => *', [
        style({ transform: 'translateX(100%)' }),
        animate(500)
      ]),
      transition('* => void', [
        animate(500, style({ transform: 'translateX(100%)' }))
      ])
    ])
  ]
})

export class BrandConfigPropertyComponent implements OnInit {

  @ViewChild(BrandAdminHeaderComponent, {static : true}) child! : BrandAdminHeaderComponent;  

  currentForm: string = "BrandConfigProperty";
  titleMessage = "Brand Config Properties";
  userform!: FormGroup;
  isListShown = true;
  isDetailsShown = false;
  isSubmitShown = false;
  isCurrentBrandShown = true;
  resultMsg = "";

  // masterConfigProperties!: ConfigProperty[];
  masterConfigProperties: ConfigProperty[] = [];
  brandConfigProperties: any[] = [];
  cols!: any[];
  displayDialog!: boolean;
  newBrandConfigProperty!: boolean; 
  offset!: number;
  configProperties: any[] = [];
  envRegions!: IEnvRegion[];
  currentBrand: IBrand;

  openBrandSelectionDialog!: boolean;
  brandCols!: any[];
  brands!: IBrand[];
  regionBaseUrl!: string;

  autoResize: boolean = true;

  hasMore: boolean = true;
  nextPageToken: number = 0;

  // vsOptions = {
  //   delay: 200,
  //   showLoader: true,
  //   lazy: true,
  //   onLazyLoad: this.onLazyLoad.bind(this)
  // };
  // loading: boolean = false;

  // onLazyLoad(event: any) {
  //   console.log('onLazyLoad', 'started');
  //   const { first, last } = event;
  //   console.log('onLazyLoad - first', first);
  //   console.log('onLazyLoad - last', last);
  //   this.getMasterConfigProperties(this.regionBaseUrl, this.nextPageToken);
  // }


  constructor(
    private fb: FormBuilder, 
    private brandConfigPropertyService: BrandConfigPropertyService, 
    private configPropertyService: ConfigPropertyService, 
    private sharedService: SharedService,
    private userContextService: UserContextService,
    private confirmationService: ConfirmationService) { 
      this.currentBrand = this.sharedService.getDummyBrand();
  }

  ngOnInit() {
    // Hide the submit button for users with read-only access type
    if (this.userContextService.userHasPrivilege('PRIV_WAL_BRAND_CONFIG_PROP_MODIFY')) {
      this.isSubmitShown = true;
    }
    // Define the columns for Brand Config Propertiess grid
    this.cols = [
      { field: 'brandConfigPropertyId', header: 'Property Id', display: 'table-cell', width:'9%'  },
      { field: 'propertyName', header: 'Property Name', display: 'table-cell', width:'23%' },
      { field: 'propertyDescription', header: 'Property Description', display: 'table-cell' , width:'23%'},
      { field: 'propertyValue', header: 'Property Value', display: 'table-cell' , width:'31%'},
      { field: 'enabled', header: 'Enabled', display: 'table-cell' , width:'8%'},
      { field: 'brandCode', header: 'Brand Code', display: 'none'},
      { field: 'createDate', header: 'Create Date', display: 'none'},
      { field: 'lastUpdateDate', header: 'Last Update Date', display: 'none'}
    ]; 
    // Define the columns for Brands grid
    this.brandCols = [
      { field: 'brandCode', header: 'Brand Code', display: 'table-cell', width:'30%' },
      { field: 'brandName', header: 'Brand Name', display: 'table-cell', width:'45%' },
      { field: 'environments', header: 'Env. Region', display: 'table-cell', width:'25%' }
    ];

    this.userform = this.fb.group({
      'brandCode': new FormControl('', [Validators.required, Validators.maxLength(3)]),
      'brandConfigPropertyId': new FormControl(null),
      'propertyName': new FormControl('', [Validators.required, Validators.maxLength(255)]),
      'propertyDescription': new FormControl('', [Validators.required]),
      'propertyValue':  new FormControl('', [Validators.required]),
      'enabled':  new FormControl(true, [Validators.required]),
      'createDate': new FormControl(''),
      'lastUpdateDate': new FormControl(''),
    });

    if (this.sharedService.isCurrentBrandKnown()) {
      this.currentBrand = this.sharedService.getCurrentBrand();
      this.child.makeTitleMessage(this.currentBrand, this.titleMessage);
      this.openBrandSelectionDialog = false;
      this.regionBaseUrl = this.currentBrand.baseUrl;
      // this.getConfigProperties(this.currentBrand.baseUrl);
      this.getMasterConfigProperties();
      this.getBrandConfigProperties(this.currentBrand.brandCode, this.currentBrand.baseUrl);
    } else {
      this.isCurrentBrandShown = false;
      this.openBrandSelectionDialog = true;
    }    
  }

  // When the user opens up the dialog to select a brand 
  onChooseBrandClick(event: any) {
    this.openBrandSelectionDialog = true;
  }

  // When a brand is chosen
  chooseBrand(item: IBrand) {
    this.openBrandSelectionDialog = false;
    if (item != null) {
      this.isCurrentBrandShown = false;
      this.currentBrand = item;
      this.sharedService.setCurrentBrand(item);    
      this.getBrandConfigProperties(item.brandCode || '', item.baseUrl || '');
      this.regionBaseUrl = item.baseUrl;
      // this.getConfigProperties(item.baseUrl || '');
      // this.hasMore = true;
      this.getMasterConfigProperties();
      this.child.makeTitleMessage(this.currentBrand, this.titleMessage);
    }
  }

 // Select common config properties 
  getConfigProperties(envRegionBaseUrl: string): void {
    var propertyName: string = '';
    var propertyScope: string = ''; 
    var propertyType: string = '';
    var offset: number = 0;
    this.configPropertyService.getConfigProperties(
      envRegionBaseUrl, propertyName, propertyScope, propertyType, offset)
        .subscribe (ConfigPropertyResponse => {
          this.configProperties.length = 0;
          this.masterConfigProperties = ConfigPropertyResponse.masterConfigPropertyList;
            for(let i = 0; i < this.masterConfigProperties.length; i++){
              var prop: string = this.masterConfigProperties[i].propertyName;
              this.configProperties.push({label: prop, value: prop});
            }
        });
  }


getMasterConfigProperties() {
  this.configProperties.length = 0;
  this.masterConfigProperties.length = 0;
  this.nextPageToken = 0;
  this.hasMore = true;
  this.getMasterConfigPropertiesChunk();
}


 // Select a chunk of master config properties 
 getMasterConfigPropertiesChunk() {
  var propertyName: string = '';
  var propertyScope: string = 'brand'; 
  var propertyType: string = '';
  this.configPropertyService.getConfigProperties(
    this.regionBaseUrl, propertyName, propertyScope, propertyType, this.nextPageToken)
      .subscribe (response => {
        console.log('getMasterConfigProperties - retrieved', response.masterConfigPropertyList.length);
        this.nextPageToken = response.paging.nextPageToken;
        this.hasMore = response.paging.hasMore;
        if (response.paging.resultsPerPage > 0 && response.masterConfigPropertyList.length > 0) {
          response.masterConfigPropertyList.forEach(item => {
            this.configProperties.push({label: item.propertyName, value: item.propertyName});
            this.masterConfigProperties.push(item);
          })
        }
        // this.configProperties = [...this.configProperties];
        console.log('getMasterConfigProperties - configProperties', this.configProperties.length);
        console.log('getMasterConfigProperties - masterConfigProperties', this.masterConfigProperties.length);
        if (this.hasMore) {
          this.getMasterConfigPropertiesChunk();
        }
      });
}



 // Select master config properties 
//  getMasterConfigProperties(envRegionBaseUrl: string, offset: number) {
//   if (!this.hasMore) return;
//   var propertyName: string = '';
//   var propertyScope: string = 'brand'; 
//   var propertyType: string = '';
//   if (offset == 0) {
//     this.configProperties.length = 0;
//     this.masterConfigProperties.length = 0;
//   }
//   this.configPropertyService.getConfigProperties(
//     envRegionBaseUrl, propertyName, propertyScope, propertyType, offset)
//       .subscribe (response => {
//         console.log('getMasterConfigProperties - retrieved', response.masterConfigPropertyList.length);
//         this.nextPageToken = response.paging.nextPageToken;
//         this.hasMore = response.paging.hasMore;
//         if (response.paging.resultsPerPage > 0 && response.masterConfigPropertyList.length > 0) {
//           response.masterConfigPropertyList.forEach(item => {
//             this.configProperties.push({label: item.propertyName, value: item.propertyName});
//             this.masterConfigProperties.push(item);
//           })
//         }
//         this.configProperties = [...this.configProperties];
//         console.log('getMasterConfigProperties - configProperties', this.configProperties.length);
//         console.log('getMasterConfigProperties - masterConfigProperties', this.masterConfigProperties.length);
//       });
// }


 // Select config properties for the chosen brand
 getBrandConfigProperties(brandCode: string, baseUrl: string): void {
  this.brandConfigPropertyService.getBrandConfigProperties(baseUrl, brandCode)
  .subscribe({
    next: (response) => {
      this.brandConfigProperties = response.brandConfigPropertyList || [];
      // Format the dates
      this.brandConfigProperties.forEach(function (arrayItem) {
        arrayItem.createDate = arrayItem.createDate.replace('T', ' ').replace('Z[UTC]','');
        arrayItem.lastUpdateDate = arrayItem.lastUpdateDate.replace('T', ' ').replace('Z[UTC]','');
        arrayItem.enabled = arrayItem.enabled ? 'True' : 'False'
      });
      },
      error: (error) => {
        },
      complete: () => {
      }
    });
  }

  onSubmit(value: string) {
    let brandConfigProperty: IBrandConfigProperty = {
      brandConfigPropertyId: this.userform.value.brandConfigPropertyId,
      brandCode: this.currentBrand.brandCode || '',
      propertyName: this.userform.value.propertyName,
      propertyDescription: this.userform.value.propertyDescription, 
      propertyValue: this.userform.value.propertyValue,
      enabled: this.userform.value.enabled,
      createDate: this.userform.value.createDate,
      lastUpdateDate: this.userform.value.lastUpdateDate
    };
    // Call the service to invoke a Web API call
    if (brandConfigProperty.brandConfigPropertyId == null || brandConfigProperty.brandConfigPropertyId == 0) {
      this.brandConfigPropertyService.addBrandConfigProperty(this.currentBrand.baseUrl || '', brandConfigProperty)
      .subscribe({
        next: (response) => {
          this.getBrandConfigProperties(this.currentBrand.brandCode || '', this.currentBrand.baseUrl || '');
          this.alertTheUser('The brand config property record has been successfully added.');
          this.isListShown = true;
          this.isDetailsShown = false;
        },
        error: (error) => {
          console.log("Error", error);
          this.alertTheUser(error.message);
        },
        complete: () => {
        }
      });
    }
    else {
      this.brandConfigPropertyService.modifyBrandConfigProperty(this.currentBrand.baseUrl || '', brandConfigProperty)
      .subscribe({
        next: (response) => {
          this.getBrandConfigProperties(this.currentBrand.brandCode || '', this.currentBrand.baseUrl || '');
          this.alertTheUser('The brand config property record has been successfully modified.');
          this.isListShown = true;
          this.isDetailsShown = false;
        },
        error: (error) => {
          console.log("Error", error);
          this.alertTheUser(error.message);
          },
        complete: () => {
        }
      });
    }
  }

  cancelDetails() {
    this.isListShown = true;
    this.isDetailsShown = false;
  }

  showDialogToAdd() {
    this.userform.setValue({brandConfigPropertyId: null, propertyName: null, propertyDescription: null,
      propertyValue: null, brandCode: this.currentBrand.brandCode, enabled: true,
      createDate: null, lastUpdateDate: null});
    this.isListShown = false;
    this.isDetailsShown = true;
    }

  // Modify existing brand variable
  onRowEdit(event: any) {
    // Call the service to invoke a Web API call
    this.brandConfigPropertyService.getBrandConfigProperty(this.currentBrand.brandCode || '', 
          event.brandConfigPropertyId, this.currentBrand.baseUrl || '')
          .subscribe({
            next: (response) => {
              let defVal = response.hasOwnProperty('propertyDescription') ? response.propertyDescription : '';
              this.userform.setValue({brandConfigPropertyId: response.brandConfigPropertyId, propertyName: response.propertyName, 
                propertyDescription: defVal, 
                propertyValue: response.propertyValue, brandCode: response.brandCode, enabled: response.enabled, 
                createDate: this.sharedService.formatFromEpoch(response.createDate),
                lastUpdateDate: this.sharedService.formatFromEpoch(response.lastUpdateDate)})
              this.isListShown = false;
              this.isDetailsShown = true;
            },
            error: (error) => {
              this.alertTheUser(error.message);
            },
            complete: () => {
            }
          });
  } 
  
  onRowDelete(event: any) {
    // Make sure that the user indeed wants to delete the variable
    this.confirmationService.confirm({
      message: 'Are you sure that you really want to delete the brand config property ' + event.propertyName + ' ?',
      header: 'Confirmation',
      key: 'brand-config-properties',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "Yes",
      rejectVisible: true,
      acceptButtonStyleClass: "p-button-success p-button-rounded",
      rejectButtonStyleClass: "p-button-danger p-button-rounded",   
      accept: () => {
        // Call the service to invoke a Web API call
        this.brandConfigPropertyService.deleteBrandConfigProperty(this.currentBrand.baseUrl || '', this.currentBrand.brandCode || '',
               event.brandConfigPropertyId)
               .subscribe({
                next: (response) => {
                  this.alertTheUser('Successfully deleted brand config property ' + event.propertyName + ' from brand '
                     + this.currentBrand.brandCode);
                  this.getBrandConfigProperties(this.currentBrand.brandCode || '', this.currentBrand.baseUrl || '');
                },
                error: (error) => {
                  this.alertTheUser(error.message);
                },
                complete: () => {
                }
              });               
      },
      reject: () => {
      }
    });
  }

  onConfigPropertyChange() {
    var propName: string = this.userform.value.propertyName;
    var propDesc: string = this.userform.value.propertyDescription;
    this.masterConfigProperties.forEach(function (arrayItem) {
      if (arrayItem.propertyName == propName) {
         propDesc = arrayItem.propertyDescription;
      }
    });
    this.userform.patchValue({propertyDescription: propDesc});
  }
  
 onRowSelect(event: any) {
  }

  alertTheUser(message: any) {
    this.confirmationService.confirm({
      message: message,
      header: 'Warning',
      key: 'brand-config-properties',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: "Ok",
      rejectVisible: false,
      acceptButtonStyleClass: "p-button-info p-button-rounded",
      accept: () => {
         return;
       }
    });
  }

}
