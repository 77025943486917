import { Component, OnInit, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { Validators,FormControl,FormGroup,FormBuilder,ValidatorFn } from '@angular/forms';
import { ConfirmationService } from "primeng/api";
import { BrandService } from '../../services/config/brand.service';
import { FilterBuilderService } from '../../services/client/filter-builder.service';
import { AdhocNotificationService } from '../../services/client/adhoc-notification.service';
import { UserContextService } from "../../services/safeguard/user-context.service";
import { SharedService } from "../../services/shared.service";
import { IBrand } from '../../interfaces/brand';
import { IFilterConfig } from '../../interfaces/filter-config';
import { IMessageTemplate } from '../../interfaces/message-template';

@Component({
  selector: 'app-adhoc-notification',
  // standalone: true,
  // imports: [],
  templateUrl: './adhoc-notification.component.html',
  styleUrl: './adhoc-notification.component.css'
})

export class AdhocNotificationComponent {

  titleMessage: string = "Ad Hoc Notification";
  adhocNotifForm!: FormGroup;

  currentBrand!: IBrand;
  brandImage: any;

  isImagePreviewShown: boolean = false;
  isBrandSelectionButtonShown: boolean = false;
  openBrandSelectionDialog: boolean = false;
  openFilterBuilderDialog: boolean = false;
  openMessageTemplateDialog: boolean = false;

  resultMsg!: string;

  // filterConfigs: any[] = [];
  // filterConfigsMaster: any[] = [];

  currentBrandSubject: Subject<any> = new Subject();

  constructor(
    private fb: FormBuilder, 
    private userContextService: UserContextService,
    private confirmationService: ConfirmationService,
    private adhocNotificationService: AdhocNotificationService,
    private sharedService: SharedService,    
    private brandService: BrandService,
    private filterBuilderService: FilterBuilderService,
    ) {}

    ngOnInit(): void {

      this.adhocNotifForm = this.fb.group({
        'filterConfigId': new FormControl('', [Validators.required, Validators.min(1)]),
        'filterName': new FormControl('', [Validators.required]),
        'filterLogic': new FormControl(''),
        'messageTemplateCode': new FormControl('', [Validators.required]),
        'messageTemplateName': new FormControl('', [Validators.required]),
        'messageTemplateHeader': new FormControl(''),
        'messageTemplateText': new FormControl(''),
      });
  
      // If the current brand is already known, then use it as the default 
      if (this.sharedService.isCurrentBrandKnown()) {
        this.currentBrand = this.sharedService.getCurrentBrand();
        this.titleMessage = this.makeTitleMessage (this.currentBrand);
        // this.getFilterConfigs(this.currentBrand.brandCode, this.currentBrand.baseUrl);
        this.openBrandSelectionDialog = false;
      } else {
  
        // There is no current brand yet - find out a set of brands (user attributes) that have been granted to them
        let grantedBrands = this.userContextService.getUserBrands();
        if (grantedBrands) {
          // Case I: the user was granted exactly one brand - make it as the current brand and skip the brand selection dialog
          if (grantedBrands.length == 1) {
            this.setSingleBrand(grantedBrands[0]);
          }
          // Case II: the user was granted multiple brands - popup the brand selection dialog
          else {
            this.isBrandSelectionButtonShown = true;
            this.openBrandSelectionDialog = true;
          }
        } 
        // Case III: no individual brands granted, rather all brands attribute
        else {
          this.isBrandSelectionButtonShown = true;
          this.openBrandSelectionDialog = true;
        }
      }
    }

  // When the user opens up the dialog to select a brand 
  onChooseBrandClick(event: any) {
    this.openBrandSelectionDialog = true;
  }

  // When a brand is chosen
  chooseBrand(item: IBrand) {
    this.openBrandSelectionDialog = false;
    if (item != null) {
      this.currentBrand = item;
      this.titleMessage = this.makeTitleMessage (item);
      this.sharedService.setCurrentBrand(item);
      // this.getFilterConfigs(item.brandCode, item.baseUrl);
      // Notify the Filter Builder component of the selected brand
      this.currentBrandSubject.next(item);
    }
  }
    
  // Set single brand as the default when the user has just one brand granted
  private setSingleBrand(brandCode: string) {
    this.brandService.getBrandWithRegion(brandCode)
    .subscribe (brand => {
      this.currentBrand = brand;
      this.titleMessage = this.makeTitleMessage (this.currentBrand);
      // this.getFilterConfigs(this.currentBrand.brandCode, this.currentBrand.baseUrl);
    });
  }

  // Retrieve all existing filter configurations for the current brand
  // private getFilterConfigs(brandCode: string, baseUrl: string) {
  //   this.filterConfigs = [];
  //   this.filterConfigsMaster = [];
  //   this.filterBuilderService.getFilterConfigsForBrand(brandCode, baseUrl || '')
  //   .subscribe({
  //     next: (response) => {
  //       if (response) {
  //         response.forEach(element => {
  //           this.filterConfigs.push({label: element.filterName, value: element.filterConfigId});
  //         });
  //         this.filterConfigsMaster = response;
  //       }
  //     },
  //     error: (error) => {
  //       // this.alertTheUser(error.message);
  //     },
  //     complete: () => {
  //     }
  //   });
  // }

  // When the user submits an on-demand request to send out an adhoc notification
  onSubmit(event: any) {
    let adhocNotifRequest = {
      brandCode: this.currentBrand.brandCode,
      filterConfigId: this.adhocNotifForm.value.filterConfigId,
      messageTemplateCode: this.adhocNotifForm.value.messageTemplateCode,
      adhocNotifName: null
    }
    this.adhocNotificationService.submitOnDemandRequest(this.currentBrand.brandCode, adhocNotifRequest, this.currentBrand.baseUrl)
    .subscribe({
      next: (response) => {
        if (response) {
          // this.alertTheUser('Successfully submitted the adhoc notification for brand '
          //     + this.currentBrand.brandCode);
        }
      },
      error: (error) => {
        // this.alertTheUser(error.message);
      },
      complete: () => {
      }
    });
  }

  // When the user wants to open up filter configurations dialog
  onSelectFilterClick() {
    this.openFilterBuilderDialog = true;
  }

  // When the user comes back from the Filter Builder dialog with a selected filter config
  selectFilterConfig(filterConfig: IFilterConfig) {
    if (filterConfig) {
      this.adhocNotifForm.patchValue({
        'filterConfigId': filterConfig.filterConfigId,
        'filterName': filterConfig.filterName,
        'filterLogic': filterConfig.filterLogic
      });
    }
    this.openFilterBuilderDialog = false;
  }

  // When the user selects a filter configuration from the dropdown
  // onChangeFilterConfig(event: any) {
  //   this.adhocNotifForm.patchValue({filterLogic: null})
  //   let filterConfigId = event.value;
  //   if (this.filterConfigsMaster) {
  //     var filter = this.filterConfigsMaster.find(item => item.filterConfigId === filterConfigId);
  //     this.adhocNotifForm.patchValue({filterLogic: JSON.stringify(filter.filterExpression)})
  //   }
  // }

  // When the user wants to open up Message Template dialog
  onSelectMessageTemplateClick() {
    this.openMessageTemplateDialog = true;
  }

  // When the user comes back from the MessageTemplate dialog with a selected filter config
  selectMessageTemplate(messageTemplate: IMessageTemplate) {
    if (messageTemplate) {
      this.adhocNotifForm.patchValue({
        'messageTemplateCode': messageTemplate.messageTemplateCode,
        'messageTemplateName': messageTemplate.messageTemplateName,
        'messageTemplateHeader': messageTemplate.messageTemplateHeader,
        'messageTemplateText': messageTemplate.messageTemplateText
     });
    }
    this.openMessageTemplateDialog = false;
  }

  // When the user wants to start over making selections
  onResetAdhocNotification() {
    this.adhocNotifForm.patchValue({
      'filterConfigId': null,
      'filterName': '',
      'filterLogic': '',
      'messageTemplateCode': '',
      'messageTemplateName': '',
      'messageTemplateHeader': '',
      'messageTemplateText': ''
   });    
  }

  private makeTitleMessage (currentBrand: IBrand) {
    var title = '';
    if (currentBrand.brandCode) {
      title += 'Brand: ' + currentBrand.brandName + ' ( ' + currentBrand.brandCode + ' ) >> ';
    }
    title += 'Ad Hoc Notification';
    if (currentBrand.logoImageContent) {
      this.brandImage = 'data:' + currentBrand.logoImageType + ';base64,' + currentBrand.logoImageContent;
      this.isImagePreviewShown = true;
    }
    return title;
  }   

}
